import { useEffect } from 'react'
import { Layout, Space, Spin, Splitter, Typography } from 'antd'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { Form } from '../Form'
import { useForms } from '../../../contexts/FormContext'
import { FormItem } from '../../../models/FormItem'
import { useAppSelector } from '../../../utils/hooks'
import { unset } from 'lodash'
const { Sider } = Layout

export const EditorWrapper = () => {
  const { itemTypes, updateForm, form } = useForms()
  const { isLoading } = useAppSelector((state) => state.form)

  const onDragEnd = (result: DropResult) => {
    const { draggableId, source, destination } = result

    // Si source ou destination sont invalides, on ignore l'action
    if (!source || !destination) return

    // Déplacement depuis le contrôle d'éléments (dépôt dans une page)
    if (source.droppableId === 'controls_droppable') {
      const selectedItem = itemTypes.find(
        (item) => String(item.id) === draggableId
      )
      if (!selectedItem) return

      // Trouve la page cible pour l'élément
      const targetPage = form?.pages?.find(
        (page) => `form_droppable_${page.id}` === destination.droppableId
      )
      if (!targetPage) {
        console.error('Page de destination introuvable.')
        return
      }

      // Crée un nouvel élément avec un nouvel ID et un ordre initial
      const newItem = {
        id: Math.max(...targetPage.items.map((item) => item.id), 0) + 1,
        question: 'Titre', // Exemple de contenu, à personnaliser
        type: selectedItem,
        order: destination.index + 1,
      }

      // Met à jour les pages avec le nouvel élément ajouté à la bonne position
      const updatedPages = form.pages.map((page) =>
        page.id === targetPage.id
          ? {
              ...page,
              items: [
                ...page.items.slice(0, destination.index),
                newItem,
                ...page.items.slice(destination.index),
              ].map((item, idx) => ({ ...item, order: idx + 1 })), // Mise à jour de l'ordre des éléments
            }
          : page
      )

      updateForm({ ...form, pages: updatedPages })
    }

    // Déplacement ou réorganisation d'un élément au sein d'une page
    else if (source.droppableId.startsWith('form_droppable')) {
      if (source.index === destination.index) return // Pas de changement si les indices sont identiques

      // Trouve la page cible pour la réorganisation des items
      const targetPage = form?.pages?.find(
        (page) => `form_droppable_${page.id}` === source.droppableId
      )
      if (!targetPage) return

      // Copie des items, réorganise-les et met à jour leur ordre
      const updatedItems = [...targetPage.items]
      const [movedItem] = updatedItems.splice(source.index, 1) // Retire l'élément déplacé
      updatedItems.splice(destination.index, 0, movedItem) // Insère à la nouvelle position

      // Met à jour l'ordre des items
      const updatedPage = {
        ...targetPage,
        items: updatedItems.map((item, idx) => ({ ...item, order: idx + 1 })),
      }

      // Met à jour les pages avec les items réorganisés
      const updatedPages = form.pages.map((page) =>
        page.id === targetPage.id ? updatedPage : page
      )

      updateForm({ ...form, pages: updatedPages })
    }
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Layout
        style={{
          minHeight: 'calc(100vh - 64px)',
          opacity: isLoading ? 0.4 : 1,
          pointerEvents: isLoading ? 'none' : 'unset',
        }}
      >
        {isLoading && (
          <Spin
            style={{
              position: 'fixed',
              left: '50%',
              top: '50%',
              zIndex: 999,
              transform: 'translate(-50%,-50%)',
            }}
          />
        )}
        {form.isLocked ? (
          <Layout style={{ width: 700, margin: 'auto', paddingBottom: 100 }}>
            <Form.Preview />
          </Layout>
        ) : (
          <>
            <Sider width={150} theme={'light'} style={{ padding: 10 }}>
              <Form.ItemsLibrary />
            </Sider>
            <Layout>
              <Splitter
                style={{
                  height: 'calc(100vh - 64px)',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Splitter.Panel
                  defaultSize="50%"
                  min="40%"
                  max="70%"
                  style={{ padding: 40 }}
                >
                  <Form.PageTitle />
                  <Form.Tabs />
                </Splitter.Panel>
                <Splitter.Panel style={{ padding: 40 }}>
                  <Form.Preview />
                </Splitter.Panel>
              </Splitter>
            </Layout>
          </>
        )}
      </Layout>
    </DragDropContext>
  )
}

export type EditorWrapperType = { Wrapper: typeof EditorWrapper }
