import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  MessageOutlined,
} from '@ant-design/icons'
import React from 'react'
import { ButtonType } from 'antd/es/button'
import { SBRMAction, SBRMType } from '../SBRMModel'
import { Button, Dropdown, Space, Tooltip } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { addUrlParams } from '../UrlHelper'
import { BaseButtonProps } from 'antd/es/button/button'
import { SBRMTypeInfos } from '../SBRMTypeInfos'
import { MetaData } from '../models/MetaData'
import { ConditionalWrapper } from './ContionalWrappers'
import { ItemType } from 'antd/es/menu/interface'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { setEntity } from '../../../store/SBRMReducer'

interface Props {
  actions: SBRMAction[]
  entity: SBRMType
  entityId?: number | string
  metadata?: MetaData[]
  layout?: 'buttons-horizontal' | 'dropdown'
  trigger?: React.ReactNode
  disabled?: BaseButtonProps['disabled']
  bypassHasAccessCheck?: boolean
}

export const Actions = ({
  actions,
  entity,
  entityId,
  metadata,
  layout = 'buttons-horizontal',
  trigger,
  disabled,
  bypassHasAccessCheck = false,
}: Props) => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const handleSBRMAction = (action: SBRMAction) => {
    if (!SBRMIsOpen) {
      // When the SBRM is closed set the url param
      // If the SBRM is already open it means we are about to open the nested drawer
      // We do not u pdate the url for the nested drawer
      // There is no need to call `setEntity` or `setSelected` here as the SBRM handles all of that when the url search changes
      addUrlParams(
        {
          action: action,
          entity: entity,
          entityId: (entityId ?? '').toString(),
        },
        metadata,
        setSearchParams
      )
    } else {
      // This will set the entity and open the nested QuickAdd drawer

      dispatch(
        setEntity({
          entity: entity,
          action: action,
          metadata: metadata,
        })
      )
      if (entityId) {
        dispatch(SBRMTypeInfos[entity].methods.setSelected(entityId))
      }
    }
  }

  // If trigger is defined we render it
  // Only the first actions - actions[0] - is considered when trigger is defined
  if (trigger) {
    // Ensure user has the right permission
    if (!bypassHasAccessCheck) {
      return <></>
    }

    return (
      <div
        onClick={() => handleSBRMAction(actions[0])}
        style={{ cursor: 'pointer' }}
      >
        {trigger}
      </div>
    )
  }

  const buttons: Record<
    SBRMAction,
    {
      key: SBRMAction
      type?: ButtonType
      danger?: boolean
      icon?: React.ReactNode
      label?: React.ReactNode
      displayButtonLabel?: boolean
      onClick: () => void
    }
  > = {
    create: {
      key: 'create',
      type: 'primary',
      label: 'Ajouter',
      displayButtonLabel: true,
      onClick: () => handleSBRMAction('create'),
    },
    update: {
      key: 'update',
      label: 'Modifier',
      icon: <EditOutlined />,
      onClick: () => handleSBRMAction('update'),
    },
    delete: {
      key: 'delete',
      label: 'Supprimer',
      danger: true,
      icon: <DeleteOutlined />,
      onClick: () => handleSBRMAction('delete'),
    },
    duplicate: {
      key: 'duplicate',
      label: 'Dupliquer',
      icon: <CopyOutlined />,
      onClick: () => handleSBRMAction('duplicate'),
    },
    view: {
      key: 'view',
      label: 'Voir',
      icon: <EyeOutlined />,
      onClick: () => handleSBRMAction('view'),
    },
    comment: {
      key: 'comment',
      label: 'Commenter',
      icon: <MessageOutlined />,
      onClick: () => handleSBRMAction('comment'),
    },
  }

  return (
    <>
      {layout === 'buttons-horizontal' && (
        <Space direction="horizontal">
          {actions.map((action) => (
            <ConditionalWrapper
              key={action}
              condition={buttons[action].displayButtonLabel !== true}
              wrapper={(children) => (
                <Tooltip title={buttons[action].label + ' ' + entity}>
                  {children}
                </Tooltip>
              )}
            >
              <Button
                type={buttons[action].type}
                danger={buttons[action].danger}
                icon={buttons[action].icon}
                onClick={buttons[action].onClick}
                disabled={disabled}
              >
                {buttons[action].displayButtonLabel === true &&
                  buttons[action].label}
              </Button>
            </ConditionalWrapper>
          ))}
        </Space>
      )}
      {layout === 'dropdown' && (
        <Dropdown
          disabled={disabled}
          menu={{
            items: actions
              .map((action) => ({
                key: action,
                label: buttons[action].label,
                onClick: buttons[action].onClick,
              }))
              .filter((i) => i !== undefined) as ItemType[],
          }}
          placement="bottomRight"
        >
          <Button icon={<EllipsisOutlined />} type={'text'} />
        </Dropdown>
      )}
    </>
  )
}
