import { Layout, theme } from 'antd'
import RightHeader from './components/RightHeader'
import { LoggedSider } from './components/LoggedSider'
import { FooterLayout } from './FooterLayout'
import { InteractionType } from '@azure/msal-browser'
import { Outlet } from 'react-router-dom'
import { useMsalAuthentication } from '@azure/msal-react'
import { loginRequest } from '../modules/msal/MSAL'
import MainHeader from './components/MainHeader'

const { Content } = Layout

const GeneralLayout = () => {
  const { token } = theme.useToken()

  // Hook used to force login in case no user is logged-in
  const { result, error } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  )

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <MainHeader />
      <Layout>
        <LoggedSider />
        <Layout style={{ padding: '0 24px 24px', background: '#f8f8f2' }}>
          <Content style={contentStyle}>
            <Outlet />
          </Content>
          <FooterLayout />
        </Layout>
      </Layout>
    </Layout>
  )
}

export default GeneralLayout
