import { Col, DatePicker, Form, FormInstance, Input, Row } from 'antd'
import { useEffect } from 'react'
import { selectSelectedEmployee } from '../../../store/EmployeeReducer'
import { useAppSelector } from '../../../utils/hooks'
import {
  emailRule,
  requiredRule,
  selectedIdIsNotHimself,
  stringRule,
} from '../../../utils/rules'
import { SBAsyncSelect } from '../../custom-input/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import dayjs from 'dayjs'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const dateFormat = 'DD/MM/YYYY'
  const item = useAppSelector(selectSelectedEmployee())

  useEffect(() => {
    form.setFieldsValue({
      ...item,
      hireDate: item?.hireDate ? dayjs(item.hireDate) : undefined,
      seniorityDate: item?.seniorityDate
        ? dayjs(item.seniorityDate)
        : undefined,
    })
  }, [item])

  if (!item) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        required
        rules={[requiredRule()]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="matricule"
        label={'Matricule'}
        rules={[requiredRule(), stringRule()]}
      >
        <Input />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="lastName"
            label={'Nom'}
            rules={[requiredRule(), stringRule()]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="firstName"
            label={'Prénom'}
            rules={[requiredRule(), stringRule()]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="email"
        label={'Email'}
        rules={[requiredRule(), emailRule()]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="hireDate" label={"Date d'embauche"} rules={[]}>
        <DatePicker style={{ width: '100%' }} format={dateFormat} />
      </Form.Item>
      <Form.Item name="seniorityDate" label={"Date d'antériorité"} rules={[]}>
        <DatePicker style={{ width: '100%' }} format={dateFormat} />
      </Form.Item>
      <SBAsyncSelect
        allowClear
        type={SBRMType.employee}
        name="manager"
        label={'Manager'}
        size="middle"
        rules={[selectedIdIsNotHimself(item)]}
      />
      <SBAsyncSelect
        type={SBRMType.company}
        name="company"
        rules={[requiredRule()]}
        label={'Société'}
        size="middle"
      />
      <SBAsyncSelect
        type={SBRMType.job}
        name="job"
        rules={[requiredRule()]}
        label={'Poste'}
        size="middle"
      />
    </Form>
  )
}

export default Update
