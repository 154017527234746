import App from '../App'
import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

// Auth
import AuthLayout from '../layout/auth/AuthLayout'
import Login from '../pages/auth/Login'
import LoginRedirect from '../pages/auth/LoginRedirect'

// Admin
import GeneralLayout from '../layout/GeneralLayout'

// Public
import LegalNotice from '../pages/legal/LegalNotice'
import DataPrivacy from '../pages/legal/DataPrivacy'
import HomePage from '../pages/admin/HomePage'
import EmployeesPage from '../pages/admin/EmployeesPage'
import FormsPage from '../pages/admin/FormsPage'
import CampaignsPags from '../pages/admin/CampaignsPage'
import SettingsRoutes from './settings/SettingsRoutes'
import { FormEditorLayout } from '../layout/FormEditorLayout'
import FormsCreatePage from '../pages/admin/FormsCreatePage'
import FormsEditPage from '../pages/admin/FormsEditPage'
import CampaignsPage from '../pages/admin/CampaignsPage'
import CampaignPage from '../pages/admin/CampaignPage'

const AppRoutes: FC = () => (
  <Routes>
    <Route element={<App />}>
      {/* Authentication routes */}
      <Route element={<AuthLayout />}>
        {/* MSAL Login redirect route */}
        <Route path="/login-redirect" element={<LoginRedirect />} />
        {/* Login page */}
        <Route path="/login" element={<Login />} />
      </Route>

      {/* Logged routes - Protected by SSO */}
      <Route element={<GeneralLayout />}>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/employees" element={<EmployeesPage />} />
        <Route path="/campaigns" element={<CampaignsPage />} />
        <Route path="/campaigns/:campaignId" element={<CampaignPage />} />

        <Route path="/forms" element={<FormsPage />} />
        <Route path="/settings/*" element={<SettingsRoutes />} />
        {/* Legal documents */}
        <Route path="/legal-notice" element={<LegalNotice />} />
        <Route path="/data-privacy" element={<DataPrivacy />} />
      </Route>
      <Route element={<FormEditorLayout />}>
        <Route path="/forms/create" element={<FormsCreatePage />} />
        <Route path="/forms/:formId" element={<FormsEditPage />} />
      </Route>

      {/* Fallback route */}
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  </Routes>
)

export default AppRoutes
