import {
  SBSelectRaw,
  SBState,
  baseReducers,
  getIdOrModelId,
} from '../utils/ReducerHelper'
import {
  SBAPICreate,
  SBAPIDelete,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIUpdate,
} from '../utils/SBAPIHelper'
import { Admin } from '../models/Admin'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { ADMIN_URL } from '../utils/urls'
import { adminSchema } from '../models/schema'

const initialState: SBState<Admin> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getAdminsSuccess,
  setQuery: setAdminQuery,
  reset: resetAdminState,
  resetQueryAndIds: resetAdminQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: number]: Admin }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectAdmins = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedAdmin = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectAdminById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectAdminsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedAdmin =
  (admin: Admin | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Admin>(admin)))

export const getAdmins = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Admin>(
    ADMIN_URL,
    params,
    [adminSchema],
    slice.actions
  )

export const createAdmin = (admin: Admin) =>
  SBAPICreate<Admin>(admin, ADMIN_URL, adminSchema, slice.actions)

export const getAdminWithId = (id: number) =>
  SBAPIFetchDispatch<Admin>(`${ADMIN_URL}/${id}`, adminSchema, slice.actions)

export const updateAdmin = (admin: Admin) =>
  SBAPIUpdate<Admin>(admin, `${ADMIN_URL}/${admin.id}`, slice.actions)

export const deleteAdmin = (admin: Admin) =>
  SBAPIDelete<Admin>(admin, `${ADMIN_URL}/${admin.id}`, slice.actions)
