import {
  Button,
  Card,
  Col,
  Flex,
  Row,
  Space,
  Statistic,
  Typography,
} from 'antd'
import PageTitle from '../../components/utils/PageTitle'
import { useAppSelector } from '../../utils/hooks'
import { selectLoggedUser } from '../../store/UserReducer'
import { useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { HOME_STATS_URL } from '../../utils/urls'
import { HomeStats } from '../../models/HomeStats'
import { APIResponse } from '../../utils/setupAxios'

const { Title, Text } = Typography

const HomePage = () => {
  const user = useAppSelector(selectLoggedUser)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [statsData, setStatsData] = useState<HomeStats | undefined>(undefined)

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(HOME_STATS_URL)
      .then((response: AxiosResponse<APIResponse<HomeStats>>) => {
        setStatsData(response.data.data)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Flex vertical className="container-row">
      <PageTitle
        level={3}
        title={
          <Space direction="horizontal" align="end">
            <Title style={{ margin: 0 }} level={1}>
              Bonjour {user?.firstName}
            </Title>
          </Space>
        }
      />
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card>
            <Statistic
              loading={isLoading}
              title={'Total employés'}
              value={statsData?.employeesTotal}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              loading={isLoading}
              title={'Campagne en cours'}
              value={statsData?.campaignOngoing}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Space direction="vertical">
              <Title level={5} className="m-0">
                {"Besoin d'aide ?"}
              </Title>
              <Button href="mailto:support@supplement-bacon.com">
                Contacter le support
              </Button>
            </Space>
          </Card>
        </Col>
      </Row>
    </Flex>
  )
}

export default HomePage
