import { Header } from 'antd/es/layout/layout'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { useForms } from '../../contexts/FormContext'
import { useAppSelector } from '../../utils/hooks'

const FormEditorHeader = () => {
  const navigate = useNavigate()
  const { onSaveClicked } = useForms()
  const { isLoading } = useAppSelector((state) => state.form)
  const { form } = useForms()

  return (
    <Header style={{ paddingLeft: 20, paddingRight: 20, background: '#fff' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          margin: 0,
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          Retour
        </Button>

        <Button
          type="primary"
          icon={<SaveOutlined />}
          onClick={onSaveClicked}
          loading={isLoading}
          disabled={isLoading || form.isLocked}
        >
          Enregistrer
        </Button>
      </div>
    </Header>
  )
}

export default FormEditorHeader
