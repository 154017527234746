import { Flex } from 'antd'
import PageTitle from '../../components/utils/PageTitle'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import {
  getEmployees,
  selectEmployees,
  setEmployeeQuery,
} from '../../store/EmployeeReducer'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Employee as EmployeeModel } from '../../models/Employee'
import { useEffect, useState } from 'react'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import SBTable from '../../components/utils/SBTable'
import { Employee } from '../../components/employee/Employee'
import { Company } from '../../components/company/Company'
import { Job } from '../../components/job/Job'
import dayjs from 'dayjs'
import { Company as CompanyModel } from '../../models/Company'
import { Job as JobModel } from '../../models/Job'
import { SBAPIFetchPaginated } from '../../utils/SBAPIHelper'
import { COMPANY_URL, JOB_URL } from '../../utils/urls'
import { initialQuery } from '../../utils/CRUDHelper'

const EmployeesPage = () => {
  const dispatch = useAppDispatch()

  const items = useAppSelector(selectEmployees())
  const { query, isLoading } = useAppSelector((state) => state.employee)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [companies, setCompanies] = useState<CompanyModel[]>([])
  const [jobs, setJobs] = useState<JobModel[]>([])
  const columns: ColumnsType<EmployeeModel> = [
    {
      key: 'name',
      title: 'Nom',
      width: 200,
      render: (record: EmployeeModel) => <Employee.Avatar model={record} />,
    },
    {
      key: 'companies',
      title: 'Société',
      filters: companies.map((company) => ({
        text: company.name,
        value: company.id,
      })),
      render: (record: EmployeeModel) => <Company.Tag id={record.company} />,
    },
    {
      key: 'positions',
      title: 'Poste',
      filters: jobs.map((job) => ({ text: job.name, value: job.id })),
      render: (record: EmployeeModel) => <Job.Tag id={record.job} />,
    },
    {
      key: 'manager',
      title: 'Manager',
      render: (record: EmployeeModel) => (
        <Employee.Avatar id={record.manager} type="condensed" />
      ),
    },
    {
      key: 'hire-date',
      title: "Date d'embauche",
      render: (record: EmployeeModel) =>
        record.hireDate ? dayjs(record.hireDate).format('DD/MM/YYYY') : '--',
    },
    {
      key: 'seniority-date',
      title: "Date d'ancienneté",
      render: (record: EmployeeModel) =>
        record.seniorityDate
          ? dayjs(record.seniorityDate).format('DD/MM/YYYY')
          : '--',
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: EmployeeModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.employee}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<EmployeeModel> | SorterResult<EmployeeModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setEmployeeQuery(newQuery))
    dispatch(getEmployees(newQuery))
  }

  useEffect(() => {
    dispatch(getEmployees(query))

    SBAPIFetchPaginated<CompanyModel[]>(COMPANY_URL, {
      ...initialQuery,
      pagination: { current: 1, pageSize: 10000 },
    }).then((response) =>
      setCompanies(response.data.sort((a, b) => a.name.localeCompare(b.name)))
    )

    SBAPIFetchPaginated<JobModel[]>(JOB_URL, {
      ...initialQuery,
      pagination: { current: 1, pageSize: 10000 },
    }).then((response) =>
      setJobs(response.data.sort((a, b) => a.name.localeCompare(b.name)))
    )
  }, [dispatch, SBRMIsOpen])

  return (
    <Flex vertical className="container-row">
      <PageTitle
        title="Employés"
        level={5}
        toolbar={<Actions entity={SBRMType.employee} actions={['create']} />}
      />
      <SBTable
        entity={SBRMType.employee}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={items}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Flex>
  )
}

export default EmployeesPage
