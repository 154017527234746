import { Rule } from 'antd/es/form'
import dayjs, { Dayjs } from 'dayjs'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Employee } from '../models/Employee'

/**
 * Localized rules
 */

export const integerRule: Rule = {
  type: 'integer',
  message: "Le nombre saisi n'est pas valide",
}

export const numberRule = function (): Rule {
  return {
    type: 'number',
    message: 'Le champ doit contenir un nombre',
  }
}

export const stringRule = function (): Rule {
  return {
    type: 'string',
    message: 'Le champ doit contenir une chaîne de caractères',
  }
}

export const urlRule = function (): Rule {
  return {
    type: 'url',
    message: "L'URL n'est pas valide",
  }
}

export const emailRule = function (): Rule {
  return {
    type: 'email',
    message: "L'adresse e-mail n'est pas valide",
  }
}

export const requiredRule = function (): Rule {
  return {
    required: true,
    message: 'Ce champ est requis',
  }
}

export const acceptedRule = function (): Rule {
  return {
    validator(_, value) {
      if (value) {
        return Promise.resolve()
      }
      return Promise.reject('Ce champ doit être accepté')
    },
  }
}

export const validPhoneNumberRule = function (): Rule {
  return ({ getFieldValue }) => ({
    validator(_, value: any) {
      return value && isValidPhoneNumber(value)
        ? Promise.resolve()
        : Promise.reject(new Error("Le numéro de téléphone n'est pas valide"))
    },
  })
}

export const haveMajorityRule = function (): Rule {
  return ({ getFieldValue }) => ({
    validator(_, value: boolean) {
      return !value
        ? Promise.reject(new Error("L'utilisateur doit être majeur"))
        : Promise.resolve()
    },
  })
}

export const dateMajorRule = function (): Rule {
  return ({ getFieldValue }) => ({
    validator(_, value: Dayjs | null) {
      if (!value) {
        return Promise.reject(new Error("L'utilisateur doit être majeur"))
      }

      // This is the date when the user has the minimum accepted age
      const majorityDate = value.add(18, 'years')

      return majorityDate < dayjs()
        ? Promise.resolve()
        : Promise.reject(new Error("L'utilisateur doit être majeur"))
    },
  })
}

export const selectedIdIsNotHimself = function (employee: Employee): Rule {
  return ({ getFieldValue }) => ({
    validator(_, value: any) {
      return value != employee.id
        ? Promise.resolve()
        : Promise.reject(
            new Error("L'employee ne peut pas se manager lui même")
          )
    },
  })
}
