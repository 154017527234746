import { schema } from 'normalizr'

const campaignSchema = new schema.Entity('campaign')
const companySchema = new schema.Entity('company')
const employeeSchema = new schema.Entity('employee')
const formSchema = new schema.Entity('form')
const formItemSchema = new schema.Entity('formItem')
const formItemOptionSchema = new schema.Entity('formItemOption')
const formItemTypeSchema = new schema.Entity('formItemType')
const formPageSchema = new schema.Entity('formPage')
const jobSchema = new schema.Entity('job')
const campaignEmployeeSchema = new schema.Entity('campaignEmployee')
const adminSchema = new schema.Entity('admin')

campaignSchema.define({
  employees: [employeeSchema],
})
companySchema.define({})
employeeSchema.define({
  manager: employeeSchema,
  company: companySchema,
  job: jobSchema,
})
formSchema.define({})
formItemSchema.define({
  option: [formItemOptionSchema],
  type: formItemTypeSchema,
})
formItemOptionSchema.define({})
formItemTypeSchema.define({})
formPageSchema.define({
  items: [formItemSchema],
})
jobSchema.define({})
campaignEmployeeSchema.define({
  employee: employeeSchema,
  campaign: campaignSchema,
  job: jobSchema,
  company: companySchema,
  manager: employeeSchema,
  form: formSchema,
})

adminSchema.define({})

export {
  campaignSchema,
  companySchema,
  employeeSchema,
  formSchema,
  formItemSchema,
  formItemOptionSchema,
  formItemTypeSchema,
  formPageSchema,
  jobSchema,
  campaignEmployeeSchema,
  adminSchema,
}
