import { Button, Flex, Space } from 'antd'
import PageTitle from '../../components/utils/PageTitle'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import SBTable from '../../components/utils/SBTable'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import {
  getCampaigns,
  selectCampaigns,
  setCampaignQuery,
} from '../../store/CampaignReducer'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Campaign as CampaignModel } from '../../models/Campaign'
import { useNavigate } from 'react-router-dom'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { useEffect } from 'react'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'

const CampaignsPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const items = useAppSelector(selectCampaigns())
  const { query, isLoading } = useAppSelector((state) => state.form)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const columns: ColumnsType<CampaignModel> = [
    {
      key: 'name',
      title: 'Nom',
      width: 300,
      render: (record: CampaignModel) => record.name,
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: CampaignModel) => (
        <Space>
          <Button
            onClick={() => {
              navigate(record.id.toString())
            }}
            icon={<EyeOutlined />}
          />
          <Actions
            actions={['delete']}
            entity={SBRMType.campaign}
            entityId={record.id}
          />
        </Space>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CampaignModel> | SorterResult<CampaignModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setCampaignQuery(newQuery))
    dispatch(getCampaigns(newQuery))
  }

  useEffect(() => {
    dispatch(getCampaigns(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Flex vertical className="container-row">
      <PageTitle
        level={5}
        title="Campagnes"
        toolbar={<Actions entity={SBRMType.campaign} actions={['create']} />}
      />

      <SBTable
        entity={SBRMType.campaign}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={items}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Flex>
  )
}

export default CampaignsPage
