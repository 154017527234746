import axios from 'axios'
import { Button } from 'antd'
import { useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { CAMPAIGN_EMPLOYEE_URL } from '../../utils/urls'

declare global {
  interface Document {
    documentMode?: any
  }
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

interface Props {
  campaignEmployeeId: string
}

export const Download = ({ campaignEmployeeId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchPDF = () => {
    setIsLoading(true)
    axios
      .get(`${CAMPAIGN_EMPLOYEE_URL}/${campaignEmployeeId}/download`, {
        method: 'GET',
        responseType: 'blob',
      })
      .then((response) => {
        const [, rawFilename] =
          response.headers['content-disposition'].split('filename=')
        // rawFilename is surrounded by quotes
        const filename = rawFilename.replace(/"/g, '')
        const blob = new Blob([response.data], { type: 'application/pdf' })

        //Check the Browser type and download the File.
        var isIE = false || !!document.documentMode
        if (isIE && window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(blob, filename)
        } else {
          var url = window.URL || window.webkitURL
          var link = url.createObjectURL(blob)
          var aDom = document.createElement('a')
          aDom.setAttribute('style', 'display:none')
          aDom.setAttribute('href', link)
          aDom.setAttribute('download', filename)
          document.body.appendChild(aDom)
          aDom.click()
          URL.revokeObjectURL(link)
          document.body.removeChild(aDom)
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Button
      onClick={fetchPDF}
      loading={isLoading}
      icon={<DownloadOutlined />}
    />
  )
}

export type DownloadType = { Download: typeof Download }
