import { App, Flex, Space, Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import {
  createForm,
  getFormWithId,
  selectFormById,
  updateForm,
} from '../../store/FormReducer'
import { useNavigate, useParams } from 'react-router-dom'
import { FormContextProvider } from '../../contexts/FormContext'
import FormEditorHeader from '../../layout/components/FormEditorHeader'
import { Form } from '../../components/form/Form'
import { FormPage } from '../../models/FormPage'
import { FormItem } from '../../models/FormItem'
import { useEffect } from 'react'

const FormsEditPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { message } = App.useApp()
  const { formId } = useParams<{ formId: string }>()

  const form = useAppSelector(selectFormById(Number(formId)))

  const { isLoading } = useAppSelector((state) => state.form)

  const validatePagesHaveItems = (pages: FormPage[]): boolean => {
    return pages.every((page) => page.items && page.items.length > 0)
  }

  const onSubmit = (values: any) => {
    if (!validatePagesHaveItems(values.pages)) {
      message.error('Chaque page doit contenir au moins un item')
      return
    }
    dispatch(
      updateForm({
        ...values,
        pages: values.pages.map((page: FormPage) => ({
          ...page,
          items: page.items.map((item: FormItem) => ({
            ...item,
            type: typeof item.type === 'object' ? item.type.id : item.type, // Remplace `type` par son `id`
          })),
        })),
      } as any)
    ).then((response: any) => {
      message.success('Sauvegardé')
    })
  }

  useEffect(() => {
    if (!Number(formId)) return
    dispatch(getFormWithId(Number(formId)))
    // alert('timp')
  }, [formId])

  if (!form) {
    return (
      <Flex>
        <Spin />
      </Flex>
    )
  }

  return (
    <FormContextProvider initialValue={form} onSubmit={onSubmit}>
      <FormEditorHeader />
      {isLoading && <Spin />}
      <Form.Wrapper />
    </FormContextProvider>
  )
}

export default FormsEditPage
