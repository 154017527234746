import { FormInstance } from 'antd'
import { MetaData, MetaDataKey } from './models/MetaData'

export enum SBRMType {
  job = 'job',
  company = 'company',
  employee = 'employee',
  form = 'form',
  campaign = 'campaign',
  campaignEmployee = 'campaignEmployee',
  admin = 'admin',
}
export const isSBRMType = (value: string): value is SBRMType =>
  Object.values<string>(SBRMType).includes(value)

export type SBRMAction =
  | 'create'
  | 'duplicate'
  | 'update'
  | 'view'
  | 'delete'
  | 'comment'
export const isSBRMAction = (value: string): value is SBRMAction =>
  ['create', 'duplicate', 'update', 'view', 'delete', 'comment'].includes(value)

export type SBRMReducer =
  | 'job'
  | 'company'
  | 'employee'
  | 'form'
  | 'campaign'
  | 'admin'

export interface SBRMFormProps {
  isNested: boolean
}
export interface SBRMFormInterface {
  handleCreate: () => Promise<boolean>
  handleUpdate: () => Promise<boolean>
  handleDelete: () => Promise<boolean>
  handleReset: () => void
}

export interface SBRMFormStepProps {
  form: FormInstance
  isNested: boolean
}

export const getMetadata = (
  metadata: MetaData[] | undefined,
  key: MetaDataKey
) => {
  const extractedData = (metadata ?? []).find((m: MetaData) => m.key === key)
  return extractedData ? extractedData.value : undefined
}

type SBRMLayoutAction = {
  container: 'drawer' | 'modal'
  content: {
    mainPanelContent?: 'form' | 'comments' | 'file-viewer'
    rightPanelContent?: 'form' | 'comments'
    leftPanelContent?: 'file-viewer'
  }
  closeToSave?: boolean
}

type SBRMLayout = {
  update?: SBRMLayoutAction
  view?: SBRMLayoutAction
  create?: SBRMLayoutAction
  duplicate?: SBRMLayoutAction
  delete?: SBRMLayoutAction
  comment?: SBRMLayoutAction
}

export type SBRMTypeInfosType<T> = Record<
  SBRMType,
  {
    // --- Not used yet START
    creatable: boolean
    updatable: boolean
    viewable: boolean
    deletable: boolean
    duplicable: boolean
    // --- Not used yet END
    methods: {
      setSelected: any
    }
    layout: SBRMLayout
    filePropertyName?: undefined | null | string // undefined -> no file | null -> resource is file | string -> property to access file
    reducerName: SBRMReducer
  }
>
