import { Tag, Typography } from 'antd'
import { Campaign as CampaignModel } from '../../models/Campaign'
import { useAppSelector } from '../../utils/hooks'
import { selectCampaignById } from '../../store/CampaignReducer'

const { Text } = Typography

interface Props {
  id?: number
  model?: CampaignModel
  type?: 'full' | 'condensed'
}

export const CampaignTag = ({ id, model }: Props) => {
  const item = model ? model : useAppSelector(selectCampaignById(id!))

  if (!item) {
    return <Tag>Campagne supprimée</Tag>
  }

  return <Tag color="purple">{item.name}</Tag>
}

export type CampaignTagType = { Tag: typeof CampaignTag }
