import { FC } from 'react'
import { Form, FormType } from './form/Form'
import { EmployeeAvatar, EmployeeAvatarType } from './Avatar'
import { EmployeeOption, EmployeeOptionType } from './Option'

export const Employee: FC &
  EmployeeAvatarType &
  FormType &
  EmployeeOptionType = (): JSX.Element => <></>

Employee.Avatar = EmployeeAvatar
Employee.Form = Form
Employee.Option = EmployeeOption
