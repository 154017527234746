import { Tag, Typography } from 'antd'
import { Form as FormModel } from '../../models/Form'
import { useAppSelector } from '../../utils/hooks'
import { selectFormById } from '../../store/FormReducer'

const { Text } = Typography

interface Props {
  id?: number
  model?: FormModel
  type?: 'full' | 'condensed'
}

export const FormTag = ({ id, model }: Props) => {
  const item = model ? model : useAppSelector(selectFormById(id!))

  if (!item) {
    return <Tag>Form supprimée</Tag>
  }

  return <Tag>{item.name}</Tag>
}

export type FormTagType = { Tag: typeof FormTag }
