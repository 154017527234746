import './index.css'
import { ConfigProvider, App } from 'antd'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { store } from './store/store'
import AppRoutes from './routes/AppRoutes'
import { inject } from '@vercel/analytics'
import { BrowserRouter as Router } from 'react-router-dom'
import MSAL from './modules/msal/MSAL'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api\.rock-your\.careers/],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: 'ryc-bo-frontend@' + process.env.npm_package_version,
})

// Enable Vercel analytics
inject({
  mode:
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'production'
      : 'development',
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Router basename="/">
    <MSAL>
      <Provider store={store}>
        <App>
          <ConfigProvider
            theme={{
              token: { colorPrimary: '#6c7cff' },
            }}
          >
            <AppRoutes />
          </ConfigProvider>
        </App>
      </Provider>
    </MSAL>
  </Router>
)
