import { Form, FormInstance, Input } from 'antd'
import { useEffect } from 'react'
import { selectSelectedEmployee } from '../../../store/EmployeeReducer'
import { useAppSelector } from '../../../utils/hooks'
import { requiredRule, stringRule } from '../../../utils/rules'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }
    //
  }, [SBRMIsOpen])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label={'Nom'}
        rules={[requiredRule(), stringRule()]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default Create
