import { Empty, Space } from 'antd'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { Form } from '../Form'
import { FormPage } from '../../../models/FormPage'

interface Props {
  page: FormPage
}
export const ItemsWrapper = ({ page }: Props) => {
  return (
    <>
      <Droppable droppableId={`form_droppable_${page.id}`} type="controls">
        {(provided) => (
          <div>
            <Space
              direction="vertical"
              style={{
                backgroundColor: '#fff',
                padding: 20,
                paddingBottom: 80,
                minHeight: '300px',
                width: '100%',
                overflow: 'auto',
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {page.items.map((data, index) => (
                <Draggable
                  key={`page_${page.id}_form_draggable_${data.id}`} // Utiliser data.id comme clé unique
                  draggableId={`page_${page.id}_form_draggable_${data.id}`}
                  index={index}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <div {...provided.dragHandleProps}>
                        <Form.FormItem item={data} page={page} />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {page.items.length < 1 && (
                <Empty
                  style={{
                    height: 300,
                    alignContent: 'center',
                  }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'Faites glisser des items dans la page'}
                ></Empty>
              )}

              {provided.placeholder}
            </Space>
          </div>
        )}
      </Droppable>
    </>
  )
}

export type ItemsWrapperType = { ItemsWrapper: typeof ItemsWrapper }
