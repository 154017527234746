import { Checkbox, Flex } from 'antd'
import PageTitle from '../../../components/utils/PageTitle'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import {
  getCompanies,
  selectCompanies,
  setCompanyQuery,
} from '../../../store/CompanyReducer'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Company as CompanyModel } from '../../../models/Company'
import { useEffect } from 'react'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import SBTable from '../../../components/utils/SBTable'

const CompaniesPage = () => {
  const dispatch = useAppDispatch()

  const items = useAppSelector(selectCompanies())
  const { query, isLoading } = useAppSelector((state) => state.company)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const columns: ColumnsType<CompanyModel> = [
    {
      key: 'name',
      title: 'Nom',
      render: (record: CompanyModel) => record.name,
    },
    {
      key: 'campaignsOnlyVisibleBySuperAdmin',
      title: 'Entretiens visibles uniquement par les super-admins',
      render: (record: CompanyModel) => (
        <Checkbox checked={record.campaignsOnlyVisibleBySuperAdmin} disabled />
      ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: CompanyModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.company}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CompanyModel> | SorterResult<CompanyModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setCompanyQuery(newQuery))
    dispatch(getCompanies(newQuery))
  }

  useEffect(() => {
    dispatch(getCompanies(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Flex vertical>
      <PageTitle
        title="Sociétés"
        level={5}
        toolbar={<Actions entity={SBRMType.company} actions={['create']} />}
      />
      <SBTable
        entity={SBRMType.company}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={items}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Flex>
  )
}

export default CompaniesPage
