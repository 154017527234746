import { forwardRef, Ref, useImperativeHandle } from 'react'
import { Alert, Form as ANTDForm } from 'antd'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'
import { createJob, deleteJob, updateJob } from '../../../store/JobReducer'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'

const Form = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metadata } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.user)

    const [createForm] = ANTDForm.useForm()
    const [updateForm] = ANTDForm.useForm()
    const [deleteForm] = ANTDForm.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) =>
        dispatch(createJob(createForm.getFieldsValue())).then(() => {
          createForm.resetFields()
          return true
        })
      )

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) =>
        dispatch(updateJob(updateForm.getFieldsValue())).then(() => {
          updateForm.resetFields()
          return true
        })
      )

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteJob(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {(action === 'create' || action === 'duplicate') && (
          <Create form={createForm} />
        )}
        {action === 'update' && <Update form={updateForm} />}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

Form.displayName = 'Form'

type FormType = { Form: typeof Form }

export { Form, type FormType }
