import { Typography } from 'antd'
import { Job as JobModel } from '../../models/Job'
import { useAppSelector } from '../../utils/hooks'
import { selectJobById } from '../../store/JobReducer'

const { Text } = Typography

interface Props {
  id?: number
  model?: JobModel
  type?: 'full' | 'condensed'
}

export const JobOption = ({ id, model }: Props) => {
  const item = model ? model : useAppSelector(selectJobById(id!))

  if (!item) {
    return <></>
  }

  return <>{item.name}</>
}

export type JobOptionType = { Option: typeof JobOption }
