import { Flex } from 'antd'
import PageTitle from '../../../components/utils/PageTitle'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { getJobs, selectJobs, setJobQuery } from '../../../store/JobReducer'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Job as JobModel } from '../../../models/Job'
import { useEffect, useState } from 'react'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import SBTable from '../../../components/utils/SBTable'

const JobsPage = () => {
  const dispatch = useAppDispatch()

  const items = useAppSelector(selectJobs())
  const { query, isLoading } = useAppSelector((state) => state.job)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const columns: ColumnsType<JobModel> = [
    {
      key: 'name',
      title: 'Nom',
      render: (record: JobModel) => record.name,
    },

    {
      key: 'actions',
      align: 'right',
      render: (record: JobModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.job}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<JobModel> | SorterResult<JobModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setJobQuery(newQuery))
    dispatch(getJobs(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)
    // Trigger on init and when SBRM is closed
    dispatch(getJobs(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Flex vertical>
      <PageTitle
        title="Postes"
        level={5}
        toolbar={<Actions entity={SBRMType.job} actions={['create']} />}
      />
      <SBTable
        entity={SBRMType.job}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={items}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Flex>
  )
}

export default JobsPage
