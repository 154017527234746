import { FC } from 'react'
import { JobTag, JobTagType } from './Tag'
import { JobOption, JobOptionType } from './Option'

import { Form, FormType } from './form/Form'

export const Job: FC &
  JobTagType &
  JobOptionType &
  FormType = (): JSX.Element => <></>

Job.Tag = JobTag
Job.Option = JobOption
Job.Form = Form
