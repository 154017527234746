import { green, orange } from '@ant-design/colors'
import {
  Card,
  Col,
  Flex,
  Progress,
  Row,
  Space,
  Spin,
  Statistic,
  Tag,
  Typography,
} from 'antd'
import PageTitle from '../../components/utils/PageTitle'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import SBTable from '../../components/utils/SBTable'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { useParams } from 'react-router-dom'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { useEffect, useState } from 'react'
import { Employee } from '../../components/employee/Employee'
import { Job } from '../../components/job/Job'
import { ArrowRightOutlined, SignatureOutlined } from '@ant-design/icons'
import {
  getCampaignWithId,
  selectCampaignById,
} from '../../store/CampaignReducer'
import dayjs from 'dayjs'
import {
  getCampaignEmployees,
  selectCampaignEmployees,
  setCampaignEmployeeQuery,
} from '../../store/CampaignEmployeeReducer'
import { TableParams } from '../../models/TableParams'
import { Company as CompanyModel } from '../../models/Company'
import { Job as JobModel } from '../../models/Job'
import { initialQuery } from '../../utils/CRUDHelper'
import { CampaignEmployee as CampaignEmployeeModel } from '../../models/Campaign'
import { Form } from '../../components/form/Form'
import { Campaign } from '../../components/campaign/Campaign'
import { CampaignEmployee } from '../../components/campaignEmployee/CampaignEmployee'
import { Company } from '../../components/company/Company'
import { SBAPIFetchPaginated } from '../../utils/SBAPIHelper'
import { COMPANY_URL, JOB_URL } from '../../utils/urls'

const { Title, Text } = Typography

const CampaignPage = () => {
  const dispatch = useAppDispatch()
  const { campaignId } = useParams<{ campaignId: string }>()

  const campaign = useAppSelector(selectCampaignById(Number(campaignId)))

  const items = useAppSelector(selectCampaignEmployees())
  const { query, isLoading } = useAppSelector((state) => state.campaignEmployee)
  const [companies, setCompanies] = useState<CompanyModel[]>([])
  const [jobs, setJobs] = useState<JobModel[]>([])

  const columns: ColumnsType<CampaignEmployeeModel> = [
    {
      key: 'name',
      title: 'Nom',
      width: 200,
      render: (record: CampaignEmployeeModel) => (
        <Employee.Avatar id={record.employee} />
      ),
    },
    {
      key: 'employee',
      title: 'Employé',
      render: (record: CampaignEmployeeModel) => (
        <Space>
          <Progress
            type="dashboard"
            percent={record.employeeProgression.progression}
            size={35}
            strokeColor={
              record.employeeProgression.progression == 100
                ? green[4]
                : orange[4]
            }
          />
          <Tag
            color={record.employeeSignedAt ? 'green' : 'orange'}
            icon={<SignatureOutlined />}
          >
            {record.employeeSignedAt ? 'Signé' : 'Non signé'}
          </Tag>
        </Space>
      ),
    },
    {
      key: 'manager',
      title: 'Manager',
      render: (record: CampaignEmployeeModel) => (
        <Flex>
          {record.manager && (
            <Space>
              <Progress
                type="dashboard"
                percent={record.managerProgression.progression}
                size={35}
                strokeColor={
                  record.managerProgression.progression == 100
                    ? green[4]
                    : orange[4]
                }
              />
              <Tag
                color={record.managerSignedAt ? 'green' : 'orange'}
                icon={<SignatureOutlined />}
              >
                {record.managerSignedAt ? 'Signé' : 'Non signé'}
              </Tag>
              <Employee.Avatar id={record.manager} type="condensed" />
            </Space>
          )}
        </Flex>
      ),
    },
    {
      key: 'form',
      title: 'Formulaire',
      render: (record: CampaignEmployeeModel) => <Form.Tag id={record.form} />,
    },
    {
      key: 'positions',
      title: 'Poste',
      filters: jobs.map((job) => ({ text: job.name, value: job.id })),
      render: (record: CampaignEmployeeModel) => <Job.Tag id={record.job} />,
    },
    {
      key: 'companies',
      title: 'Société',
      filters: companies.map((company) => ({
        text: company.name,
        value: company.id,
      })),
      render: (record: CampaignEmployeeModel) => (
        <Company.Tag id={record.company} />
      ),
    },
    {
      key: 'actions',
      title: 'PDF',
      align: 'center',
      render: (record: CampaignEmployeeModel) => (
        <Space>
          {record.canDownloadPDF && (
            <CampaignEmployee.Download campaignEmployeeId={record.id} />
          )}
        </Space>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<CampaignEmployeeModel>
      | SorterResult<CampaignEmployeeModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setCampaignEmployeeQuery(newQuery))
    dispatch(getCampaignEmployees(newQuery))
  }

  useEffect(() => {
    if (!campaignId || !Number(campaignId)) return
    dispatch(getCampaignWithId(Number(campaignId)))

    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { campaigns: [Number(campaignId)] },
    }
    dispatch(setCampaignEmployeeQuery(baseQuery))
    dispatch(getCampaignEmployees(baseQuery))

    SBAPIFetchPaginated<CompanyModel[]>(COMPANY_URL, {
      ...initialQuery,
      pagination: { current: 1, pageSize: 10000 },
    }).then((response) =>
      setCompanies(response.data.sort((a, b) => a.name.localeCompare(b.name)))
    )

    SBAPIFetchPaginated<JobModel[]>(JOB_URL, {
      ...initialQuery,
      pagination: { current: 1, pageSize: 10000 },
    }).then((response) =>
      setJobs(response.data.sort((a, b) => a.name.localeCompare(b.name)))
    )
  }, [campaignId])

  if (!campaign) {
    return (
      <Flex>
        <Spin />
      </Flex>
    )
  }

  return (
    <Flex vertical className="container-row">
      <PageTitle
        level={3}
        title={
          <Space direction="horizontal" align="end">
            <Title style={{ margin: 0 }} level={1}>
              {campaign.name}
            </Title>
            <Text type="secondary" style={{ lineHeight: 2 }}>
              {dayjs(campaign.start).format('DD/MM/YYYY')}{' '}
              <ArrowRightOutlined />{' '}
              {dayjs(campaign.softEnd).format('DD/MM/YYYY')} | Réelle fin le{' '}
              {dayjs(campaign.hardEnd).format('DD/MM/YYYY')}
            </Text>
          </Space>
        }
      />
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card>
            <Statistic
              title={'Total employés'}
              value={campaign.totalEmployees}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title={'Réponses employés'}
              value={campaign.totalAnswersEmployee}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title={'Réponses manager'}
              value={campaign.totalAnswersManager}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title={'Signés'} value={campaign.totalSigned} />
          </Card>
        </Col>
        <Col span={24}>
          <Campaign.SendEmail campaign={campaign} />
        </Col>
      </Row>

      <PageTitle level={5} title="Employés" />
      <SBTable
        entity={SBRMType.campaignEmployee}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={items}
        pagination={query.pagination}
        loading={isLoading}
        hideCreateButton
        onChange={handleTableChange}
      />
    </Flex>
  )
}

export default CampaignPage
