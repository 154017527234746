import { Empty } from 'antd'
import Table, { TableProps } from 'antd/es/table'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { MetaData } from '../../modules/sbrm/models/MetaData'
import { useAppSelector } from '../../utils/hooks'
import { Actions } from '../../modules/sbrm/components/Actions'

const SBTable = <RecordType extends object = any>(
  props: React.PropsWithChildren<
    TableProps<RecordType> & {
      entity: SBRMType
      emptyActionsMetadata?: MetaData[]
      hideCreateButton?: boolean
    }
  >
) => {
  const { isOpen: nestedIsOpen } = useAppSelector((state) => state.SBRM.nested)

  return (
    <Table
      {...props}
      locale={{
        ...props.locale,
        emptyText: !props.loading && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={'Aucun résultat trouvé'}
          >
            {/* Testing hideCreateButton like that ensure to hide the button only when the parameter is specified */}
            {!nestedIsOpen && !(props.hideCreateButton === true) && (
              <Actions
                actions={['create']}
                entity={props.entity}
                metadata={props.emptyActionsMetadata}
              />
            )}
          </Empty>
        ),
      }}
    />
  )
}

export default SBTable
