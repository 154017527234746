import { AvatarProps } from 'antd/es/avatar'
import { Avatar, Space, Typography } from 'antd'
import { useAppSelector } from '../../utils/hooks'
import { Employee as EmployeeModel } from '../../models/Employee'
import { selectEmployeeById } from '../../store/EmployeeReducer'

const { Text } = Typography

interface Props {
  id?: number
  model?: EmployeeModel
  size?: AvatarProps['size']
}

export const EmployeeOption = ({ id, model, size = 'default' }: Props) => {
  const employee = id ? useAppSelector(selectEmployeeById(id)) : model

  if (!employee) {
    return <></>
  }

  return (
    <Space direction="horizontal">
      <Avatar
        size={size == 'large' ? 60 : size}
        style={{
          color: '#fff',
          backgroundColor: '#5d5df6',
          fontSize: size == 'small' ? 11 : undefined,
        }}
      >
        <div style={{ textTransform: 'uppercase' }}>
          {employee.firstName && employee.firstName.slice(0, 1)}
          {employee.lastName && employee.lastName.slice(0, 1)}
          {!employee.firstName && !employee.lastName && '--'}
        </div>
      </Avatar>

      <Space.Compact direction="vertical">
        <Space.Compact direction="vertical">
          <Text>
            {employee.firstName} {employee.lastName}
          </Text>
          {size != 'small' && (
            <Text type="secondary">#{employee.matricule}</Text>
          )}
        </Space.Compact>
      </Space.Compact>
    </Space>
  )
}

export type EmployeeOptionType = { Option: typeof EmployeeOption }
