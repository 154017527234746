import { Tag, Typography } from 'antd'
import { Company as CompanyModel } from '../../models/Company'
import { useAppSelector } from '../../utils/hooks'
import { selectCompanyById } from '../../store/CompanyReducer'

const { Text } = Typography

interface Props {
  id?: number
  model?: CompanyModel
  type?: 'full' | 'condensed'
}

export const CompanyTag = ({ id, model }: Props) => {
  const item = model ? model : useAppSelector(selectCompanyById(id!))

  if (!item) {
    return <Tag>Société supprimée</Tag>
  }

  return <Tag color="purple">{item.name}</Tag>
}

export type CompanyTagType = { Tag: typeof CompanyTag }
