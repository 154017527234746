import { Layout, Menu, theme } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  FormOutlined,
  HomeOutlined,
  NotificationOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { extractSectionFromPath } from '../../utils/RouterHelpers'

const { Sider } = Layout

interface Props {}

export const LoggedSider = ({}: Props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [selectedKey, setSelectedKey] = useState<string>('home')

  const { token } = theme.useToken()

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: '#fff',
    paddingTop: 10,
    border: 0,
  }

  useEffect(() => {
    setSelectedKey(extractSectionFromPath(pathname, 1))
  }, [pathname])

  return (
    <Sider collapsed={false} style={contentStyle}>
      <Menu
        style={contentStyle}
        selectedKeys={[selectedKey]}
        mode="inline"
        items={[
          {
            key: 'home',
            icon: <HomeOutlined />,
            label: 'Accueil',
            onClick: () => navigate('/'),
          },
          {
            key: 'employees',
            icon: <UserOutlined />,
            label: 'Employés',
            onClick: () => navigate('/employees'),
          },
          {
            key: 'campaigns',
            icon: <NotificationOutlined />,
            label: 'Campagnes',
            onClick: () => navigate('/campaigns'),
          },
          {
            key: 'forms',
            icon: <FormOutlined />,
            label: 'Formulaires',
            onClick: () => navigate('/forms'),
          },
          {
            key: 'settings',
            icon: <SettingOutlined />,
            label: 'Réglages',
            onClick: () => navigate('/settings'),
          },
        ]}
      />
    </Sider>
  )
}
