import { Button, Divider, Flex, Input, Space, Typography } from 'antd'
import { useState } from 'react'
import { FormItem } from '../../../models/FormItem'
import {
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { FormItemOption } from '../../../models/FormItemOption'
import { FormPage } from '../../../models/FormPage'
import { useForms } from '../../../contexts/FormContext'
const { Text } = Typography

// Fonction pour convertir un texte en slug
const slugify = (text: string) => {
  return text
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Remplace les espaces par des tirets
    .replace(/[^\w\-]+/g, '') // Supprime les caractères non alphanumériques
}

export const FormItemOptionEditor = ({ formItem }: { formItem: FormItem }) => {
  const { form, updateForm } = useForms()
  const [options, setOptions] = useState<FormItemOption[]>(
    formItem.options || []
  )

  const generateUniqueOrder = (): number => {
    const orders = options.map((option) => option.order)
    return Math.max(...orders, 0) + 1
  }

  const generateUniqueId = (): number => {
    const ids = options.map((option) => option.id)
    return Math.max(...ids, 0) + 1
  }

  const handleAddOption = () => {
    const newOption: FormItemOption = {
      id: generateUniqueId(),
      name: '',
      value: '',
      order: generateUniqueOrder(),
    }
    const updatedOptions = [...options, newOption]
    setOptions(updatedOptions)
    updateForm({
      ...form,
      pages: form.pages.map((page: FormPage) => ({
        ...page,
        items: page.items.map((item) =>
          item === formItem ? { ...item, options: updatedOptions } : item
        ),
      })),
    })
  }

  const handleOptionChange = (index: number, value: string) => {
    const updatedOptions = options.map((option, i) =>
      i === index
        ? {
            ...option,
            label: value,
            name: value,
            value: slugify(value),
          }
        : option
    )
    setOptions(updatedOptions)
    updateForm({
      ...form,
      pages: form.pages.map((page: FormPage) => ({
        ...page,
        items: page.items.map((item) =>
          item === formItem ? { ...item, options: updatedOptions } : item
        ),
      })),
    })
  }

  const handleMoveOption = (index: number, direction: 'up' | 'down') => {
    const newOptions = [...options]
    const targetIndex = direction === 'up' ? index - 1 : index + 1
    if (targetIndex >= 0 && targetIndex < newOptions.length) {
      ;[newOptions[index], newOptions[targetIndex]] = [
        newOptions[targetIndex],
        newOptions[index],
      ]
      setOptions(newOptions)
      updateForm({
        ...form,
        pages: form.pages.map((page: FormPage) => ({
          ...page,
          items: page.items.map((item) =>
            item === formItem ? { ...item, options: newOptions } : item
          ),
        })),
      })
    }
  }

  const handleRemoveOption = (index: number) => {
    const updatedOptions = options.filter((_, i) => i !== index)
    setOptions(updatedOptions)
    updateForm({
      ...form,
      pages: form.pages.map((page: FormPage) => ({
        ...page,
        items: page.items.map((item) =>
          item === formItem ? { ...item, options: updatedOptions } : item
        ),
      })),
    })
  }

  return (
    <>
      <Divider />
      <Space direction="vertical" className="w-full">
        <Space className="w-full space-between">
          <Text>Options :</Text>
          <Button
            icon={<PlusOutlined />}
            size="small"
            onClick={handleAddOption}
          />
        </Space>

        {options.map((option, index) => (
          <Flex key={option.id} className="w-full space-between" gap={10}>
            <Input
              className="w-full"
              placeholder="Label"
              value={option.name}
              onChange={(e) => handleOptionChange(index, e.target.value)}
            />
            <Space>
              <Button
                icon={<ArrowUpOutlined />}
                onClick={() => handleMoveOption(index, 'up')}
                disabled={index === 0}
              />
              <Button
                icon={<ArrowDownOutlined />}
                onClick={() => handleMoveOption(index, 'down')}
                disabled={index === options.length - 1}
              />
              <Button
                danger
                onClick={() => handleRemoveOption(index)}
                icon={<DeleteOutlined />}
              />
            </Space>
          </Flex>
        ))}
      </Space>
    </>
  )
}

export type FormItemOptionEditorType = {
  ItemOptionEditor: typeof FormItemOptionEditor
}
