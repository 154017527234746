import axios from 'axios'
import { Outlet } from 'react-router-dom'
import { setupAxios } from './utils/setupAxios'
import SBRM from './modules/sbrm/SBRM'
import getConfigInstance from './components/custom-input/sb-async-select/config/SBAsyncSelectConfig'
import SBAsyncSelectDefaultConfig from './components/custom-input/sb-async-select/data/default'

function App() {
  /**
   * Inject interceptors for axios.
   *
   * @see https://github.com/axios/axios#interceptors
   */
  setupAxios(axios)

  const { setValue } = getConfigInstance()
  setValue(SBAsyncSelectDefaultConfig)

  return (
    <>
      <Outlet />
      <SBRM />
    </>
  )
}

export default App
