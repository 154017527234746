import {
  SBSelectRaw,
  SBState,
  baseReducers,
  getIdOrModelId,
} from '../utils/ReducerHelper'
import {
  SBAPICreate,
  SBAPIDelete,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIUpdate,
} from '../utils/SBAPIHelper'
import { Form } from '../models/Form'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { FORM_URL } from '../utils/urls'
import { formSchema } from '../models/schema'

const initialState: SBState<Form> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getFormsSuccess,
  setQuery: setFormQuery,
  reset: resetFormState,
  resetQueryAndIds: resetFormQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: number]: Form }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectForms = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedForm = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectFormById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectFormsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedForm =
  (form: Form | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Form>(form)))

export const getForms = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Form>(
    FORM_URL,
    params,
    [formSchema],
    slice.actions
  )

export const createForm = (form: Form) =>
  SBAPICreate<Form>(form, FORM_URL, formSchema, slice.actions)

export const getFormWithId = (id: number) =>
  SBAPIFetchDispatch<Form>(`${FORM_URL}/${id}`, formSchema, slice.actions)

export const updateForm = (form: Form) =>
  SBAPIUpdate<Form>(form, `${FORM_URL}/${form.id}`, slice.actions)

export const deleteForm = (form: Form) =>
  SBAPIDelete<Form>(form, `${FORM_URL}/${form.id}`, slice.actions)
