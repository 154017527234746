import { Card, Space, Typography } from 'antd'
import {
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import { useForms } from '../../../contexts/FormContext'
import { FontSizeOutlined } from '@ant-design/icons'
import { getIconByTypeId } from './utils/IconHelper'

const { Text } = Typography

export const ItemsLibrary = () => {
  const { itemTypes } = useForms()
  const getIconById = (id: number) => {
    switch (id) {
      case 1:
        return <FontSizeOutlined />
        break

      default:
        break
    }
  }
  return (
    <Droppable
      droppableId="controls_droppable"
      type="controls"
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <Space
          className="w-full"
          direction="vertical"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {itemTypes.map((control, index) => (
            <Draggable
              key={`control_draggable_${control.id}`}
              draggableId={String(control.id)}
              index={index}
            >
              {(
                provided: DraggableProvided,
                snapshot: DraggableStateSnapshot
              ) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <Card key={control.id} styles={{ body: { padding: 10 } }}>
                    <Space
                      direction="vertical"
                      size={0}
                      className="w-full"
                      style={{ alignItems: 'center', textAlign: 'center' }}
                    >
                      {getIconByTypeId(control.id)}
                      <Text>{control.name}</Text>
                    </Space>
                  </Card>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </Space>
      )}
    </Droppable>
  )
}

export type ItemsLibraryType = { ItemsLibrary: typeof ItemsLibrary }
