import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser'
import { LogLevel } from '@azure/msal-browser'
import { Configuration, RedirectRequest } from '@azure/msal-browser'
import ClientSideNavigation from './ClientSideNavigation'
import { MsalProvider } from '@azure/msal-react'
import { PropsWithChildren, useEffect, useState } from 'react'

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MS_CLIENT_ID!,
    authority: process.env.REACT_APP_MS_AUTHORITY,
    redirectUri: '/login-redirect',
    postLogoutRedirectUri: '/login',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            //console.error(message)
            return
          case LogLevel.Info:
            //console.info(message)
            return
          case LogLevel.Verbose:
            //console.debug(message)
            return
          case LogLevel.Warning:
            //console.warn(message)
            return
          default:
            return
        }
      },
    },
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ['User.Read', 'email'],
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}

export const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

const MSAL = ({ children }: PropsWithChildren) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  useEffect(() => {
    const setup = async () => {
      await msalInstance.initialize()
      setIsInitialized(true)
    }
    setup()
  }, [])

  return (
    <ClientSideNavigation pca={msalInstance}>
      <MsalProvider instance={msalInstance}>
        {!isInitialized && <></>}
        {isInitialized && children}
      </MsalProvider>
    </ClientSideNavigation>
  )
}

export default MSAL
