import UserReducer from './UserReducer'
import JobReducer from './JobReducer'
import CompanyReducer from './CompanyReducer'
import EmployeeReducer from './EmployeeReducer'
import FormReducer from './FormReducer'
import CampaignReducer from './CampaignReducer'
import CampaignEmployeeReducer from './CampaignEmployeeReducer'
import AdminReducer from './AdminReducer'
import SBRMReducer from './SBRMReducer'

import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
  reducer: {
    user: UserReducer,
    job: JobReducer,
    company: CompanyReducer,
    employee: EmployeeReducer,
    form: FormReducer,
    campaign: CampaignReducer,
    campaignEmployee: CampaignEmployeeReducer,
    admin: AdminReducer,
    SBRM: SBRMReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
