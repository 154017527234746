import { useEffect } from 'react'
import { useMsal, useMsalAuthentication } from '@azure/msal-react'
import { BrowserUtils, InteractionType } from '@azure/msal-browser'
import { Avatar, Dropdown, MenuProps, Space, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { loginRequest } from '../../modules/msal/MSAL'
import { selectLoggedUser, setLoggedUser } from '../../store/UserReducer'
import { callMsGraphTokenUserInfos } from '../../modules/msal/MsGraphApiCall'

const { Text } = Typography

const RightHeader = () => {
  const dispatch = useAppDispatch()

  const { instance } = useMsal()
  const { result, error } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  )

  const user = useAppSelector(selectLoggedUser)

  const items: MenuProps['items'] = [
    {
      key: 2,
      label: <Text>Déconnexion</Text>,
      onClick: () =>
        instance.logoutRedirect({
          account: instance.getActiveAccount(),
          onRedirectNavigate: () => !BrowserUtils.isInIframe(),
        }),
    },
  ]

  useEffect(() => {
    if (!!error) {
      // Error occurred attempting to acquire a token, either handle the error or do nothing
      return
    }

    if (result) {
      callMsGraphTokenUserInfos().then((response) => {
        dispatch(
          setLoggedUser({
            id: response.id,
            firstName: response.givenName,
            lastName: response.surname,
            email: response.mail,
          })
        )
      })
    }
  }, [error, result])

  return (
    <Space>
      <Dropdown menu={{ items }} placement="bottomLeft" arrow>
        <Avatar
          style={{
            color: '#fff',
            backgroundColor: '#6c7cff',
            cursor: 'pointer',
          }}
        >
          {user && user.firstName && user.lastName
            ? user!.firstName.slice(0, 1) + user!.lastName.slice(0, 1)
            : '--'}
        </Avatar>
      </Dropdown>
    </Space>
  )
}

export default RightHeader
