import {
  Button,
  Card,
  Collapse,
  CollapseProps,
  DatePicker,
  Flex,
  Form,
  FormInstance,
  Input,
  Space,
  Typography,
} from 'antd'
import { useEffect } from 'react'
import { selectSelectedEmployee } from '../../../store/EmployeeReducer'
import { useAppSelector } from '../../../utils/hooks'
import { requiredRule, stringRule } from '../../../utils/rules'
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { SBAsyncSelect } from '../../custom-input/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import TextArea from 'antd/es/input/TextArea'
import { initialQuery } from '../../../utils/CRUDHelper'

const { Text } = Typography
interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Informations générales',
      children: (
        <Flex vertical>
          <Form.Item
            name="name"
            label={'Nom de la campagne'}
            rules={[requiredRule(), stringRule()]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="start"
            label={'Date de début'}
            rules={[requiredRule()]}
          >
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item
            name="softEnd"
            label={'Date de fin visible'}
            rules={[requiredRule()]}
          >
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item
            name="hardEnd"
            label={'Date de fin réelle'}
            rules={[requiredRule()]}
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Flex>
      ),
    },
    {
      key: '2',
      label: 'Formulaires',
      children: (
        <Flex vertical>
          <Form.List name="formsAssociation">
            {(fields, { add, remove }) => (
              <Flex vertical gap={16}>
                {fields.map(({ key, name, ...restField }) => (
                  <Card key={key} bordered styles={{ body: { padding: 10 } }}>
                    <Space
                      className="w-full space-between"
                      style={{ marginBottom: 10 }}
                    >
                      <Text type="secondary">Association</Text>
                      <DeleteOutlined
                        style={{ color: 'red' }}
                        onClick={() => remove(name)}
                      />
                    </Space>

                    <Flex gap={16} align="baseline" className="w-full">
                      <SBAsyncSelect
                        type={SBRMType.form}
                        {...restField}
                        name={[name, 'form']}
                        rules={[requiredRule()]}
                        label={'Formulaire'}
                        size="middle"
                        className="w-full m-0"
                      />
                      <SBAsyncSelect
                        multiple
                        type={SBRMType.job}
                        {...restField}
                        name={[name, 'jobs']}
                        rules={[requiredRule()]}
                        label={'Poste'}
                        size="middle"
                        className="w-full m-0"
                        initialQuery={{
                          ...initialQuery,
                          pagination: {
                            ...initialQuery.pagination,
                            pageSize: 1000,
                          },
                        }}
                      />
                    </Flex>
                  </Card>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    className="w-full"
                  >
                    Ajouter un formulaire
                  </Button>
                </Form.Item>
              </Flex>
            )}
          </Form.List>
        </Flex>
      ),
    },
    {
      key: '3',
      label: 'Contenu du mail envoyé à chaque employé',
      children: (
        <Flex vertical>
          <Form.Item
            name="mailSubject"
            label={'Objet du mail'}
            rules={[requiredRule(), stringRule()]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mailBody"
            label={'Corps du mail'}
            rules={[requiredRule()]}
          >
            <TextArea rows={6} />
          </Form.Item>
        </Flex>
      ),
    },
  ]
  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }
    //
  }, [SBRMIsOpen])

  return (
    <Form form={form} layout="vertical">
      <Collapse accordion items={items} defaultActiveKey={['1']} />
    </Form>
  )
}

export default Create
