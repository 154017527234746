import { Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { createForm } from '../../store/FormReducer'
import { useNavigate } from 'react-router-dom'
import { FormContextProvider } from '../../contexts/FormContext'
import FormEditorHeader from '../../layout/components/FormEditorHeader'
import { Form } from '../../components/form/Form'
import { FormPage } from '../../models/FormPage'
import { FormItem } from '../../models/FormItem'

const FormsCreatePage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { isLoading } = useAppSelector((state) => state.form)

  const onSubmit = (values: any) => {
    dispatch(
      createForm({
        ...values,
        pages: values.pages.map((page: FormPage) => ({
          ...page,
          items: page.items.map((item: FormItem) => ({
            ...item,
            type: typeof item.type === 'object' ? item.type.id : item.type, // Remplace `type` par son `id`
          })),
        })),
      } as any)
    ).then((response: any) => {
      navigate(`/forms/${response.data.message}`)
    })
  }

  return (
    <FormContextProvider onSubmit={onSubmit}>
      <FormEditorHeader />
      {isLoading && <Spin />}
      <Form.Wrapper />
    </FormContextProvider>
  )
}

export default FormsCreatePage
