import { Typography } from 'antd'
import { Company as CompanyModel } from '../../models/Company'
import { useAppSelector } from '../../utils/hooks'
import { selectCompanyById } from '../../store/CompanyReducer'

const { Text } = Typography

interface Props {
  id?: number
  model?: CompanyModel
  type?: 'full' | 'condensed'
}

export const CompanyOption = ({ id, model }: Props) => {
  const item = model ? model : useAppSelector(selectCompanyById(id!))

  if (!item) {
    return <></>
  }

  return <>{item.name}</>
}

export type CompanyOptionType = { Option: typeof CompanyOption }
