import { FC } from 'react'

import { CompanyTag, CompanyTagType } from './Tag'
import { CompanyOption, CompanyOptionType } from './Option'

import { Form, FormType } from './form/Form'

export const Company: FC &
  CompanyTagType &
  CompanyOptionType &
  FormType = (): JSX.Element => <></>

Company.Tag = CompanyTag
Company.Option = CompanyOption
Company.Form = Form
