import { FC } from 'react'

import { CampaignTag, CampaignTagType } from './Tag'
import { CampaignOption, CampaignOptionType } from './Option'

import { Form, FormType } from './form/Form'
import { CampaignSendEmailType, SendEmail } from './SendEmail'

export const Campaign: FC &
  CampaignTagType &
  CampaignOptionType &
  FormType &
  CampaignSendEmailType = (): JSX.Element => <></>

Campaign.Tag = CampaignTag
Campaign.Option = CampaignOption
Campaign.Form = Form
Campaign.SendEmail = SendEmail
