import {
  Card,
  Checkbox,
  Flex,
  Form,
  Input,
  Rate,
  Select,
  Space,
  Steps,
  StepsProps,
  Typography,
} from 'antd'
import { useForms } from '../../../contexts/FormContext'
import { FormItem } from '../../../models/FormItem'
import { requiredRule } from '../../../utils/rules'
import { FormPage } from '../../../models/FormPage'

const { Title, Text } = Typography
const { TextArea } = Input

export const Preview = () => {
  const { form, activeTabId, updateActiveTabId } = useForms()
  const activePage: FormPage = form.pages.filter(
    (page) => page.id.toString() === activeTabId
  )[0]

  const steps = (form.pages ?? []).map((page) => ({
    title: page.name,
  })) as StepsProps[]
  const getControl = (item: FormItem) => {
    switch (item.type.id) {
      case 1:
        return (
          <Space size={0} key={item.id} direction="vertical">
            <Title level={5}>{item.question}</Title>
            <Text italic type="secondary">
              {item.description}
            </Text>
          </Space>
        )
      case 2:
        return (
          <Form.Item
            key={item.id}
            label={
              <Space size={0} direction="vertical">
                <Text>{item.question}</Text>
                <Text italic type="secondary">
                  {item.description}
                </Text>
              </Space>
            }
            name={item.id}
            rules={item.required ? [requiredRule()] : []}
          >
            <Input size="small" variant="outlined" />
          </Form.Item>
        )
      case 3:
        return (
          <Form.Item
            key={item.id}
            label={
              <Space size={0} direction="vertical">
                <Text>{item.question}</Text>
                <Text italic type="secondary">
                  {item.description}
                </Text>
              </Space>
            }
            name={item.id}
            rules={item.required ? [requiredRule()] : []}
          >
            <TextArea rows={4} />
          </Form.Item>
        )
      case 5:
        return (
          <Form.Item
            key={item.id}
            label={
              <Space size={0} direction="vertical">
                <Text>{item.question}</Text>
                <Text italic type="secondary">
                  {item.description}
                </Text>
              </Space>
            }
            name={item.id}
            rules={item.required ? [requiredRule()] : []}
          >
            <Select
              className="w-full"
              options={item.options?.map((o) => ({ ...o, label: o.name }))}
            />
          </Form.Item>
        )
      case 4:
        return (
          <Form.Item
            key={item.id}
            label={
              <Space size={0} direction="vertical">
                <Text>{item.question}</Text>
                <Text italic type="secondary">
                  {item.description}
                </Text>
              </Space>
            }
            name={item.id}
            rules={item.required ? [requiredRule()] : []}
          >
            <Checkbox.Group>
              <Space size={0} direction="vertical">
                {item.options?.map((option) => (
                  <Checkbox key={item.id} value={option.value}>
                    {option.name}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Form.Item>
        )
      case 6:
        return (
          <Form.Item
            key={item.id}
            label={
              <Space size={0} direction="vertical">
                <Text>{item.question}</Text>
                <Text type="secondary">{item.description}</Text>
              </Space>
            }
            name={item.id}
            rules={item.required ? [requiredRule()] : []}
          >
            <Rate style={{ fontSize: 35 }} className="w-full" />
          </Form.Item>
        )
      case 7:
        return <div style={{ height: 50 }}></div>
      default:
        return <div>Other</div>
    }
  }

  return (
    <Flex vertical gap={20}>
      <Title level={3}>{form.name}</Title>
      <Steps
        size="small"
        current={Number(activeTabId)}
        items={steps}
        onChange={(id) => updateActiveTabId(String(form.pages[id].id))}
      />
      <Card>
        <Form layout="vertical" className="w-full">
          {activePage.items &&
            activePage.items.map(
              (item) => item.question != '' && <>{getControl(item)}</>
            )}
        </Form>
      </Card>
    </Flex>
  )
}

export type PreviewType = { Preview: typeof Preview }
