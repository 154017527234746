import {
  AlignLeftOutlined,
  CheckSquareOutlined,
  ColumnHeightOutlined,
  FontSizeOutlined,
  OrderedListOutlined,
  StarOutlined,
} from '@ant-design/icons'

export const getIconByTypeId = (id: number) => {
  switch (id) {
    case 1:
      return <FontSizeOutlined />
    case 2:
      return <AlignLeftOutlined />
    case 3:
      return <FontSizeOutlined />
    case 4:
      return <OrderedListOutlined />
    case 5:
      return <CheckSquareOutlined />
    case 6:
      return <StarOutlined />
    case 7:
      return <ColumnHeightOutlined />
      break

    default:
      break
  }
}
