import { SBModel } from '../../models/SBmodel'
import { setSelectedAdmin } from '../../store/AdminReducer'
import { setSelectedCampaign } from '../../store/CampaignReducer'
import { setSelectedCompany } from '../../store/CompanyReducer'
import { setSelectedEmployee } from '../../store/EmployeeReducer'
import { setSelectedForm } from '../../store/FormReducer'
import { setSelectedJob } from '../../store/JobReducer'
import { SBRMType, SBRMTypeInfosType } from './SBRMModel'

export const SBRMTypeInfos: SBRMTypeInfosType<SBModel> = {
  [SBRMType.job]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedJob,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'job',
  },
  [SBRMType.company]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedCompany,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'company',
  },
  [SBRMType.admin]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedAdmin,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'admin',
  },
  [SBRMType.employee]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedEmployee,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'employee',
  },
  [SBRMType.form]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedForm,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'form',
  },
  [SBRMType.campaign]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedCampaign,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'campaign',
  },
  [SBRMType.campaignEmployee]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedCampaign,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'campaign',
  },
}
