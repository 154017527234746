import { Avatar, Space, Tooltip, Typography } from 'antd'
import { AvatarProps } from 'antd/es/avatar'
import { Employee as EmployeeModel } from '../../models/Employee'
import { useAppSelector } from '../../utils/hooks'
import { selectEmployeeById } from '../../store/EmployeeReducer'
import { MailOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const { Text } = Typography

interface Props {
  id?: number
  model?: EmployeeModel
  size?: AvatarProps['size']
  type?: 'full' | 'condensed'
}

export const EmployeeAvatar = ({
  id,
  model,
  size = 'default',
  type = 'full',
}: Props) => {
  const employee = id ? useAppSelector(selectEmployeeById(id)) : model

  if (!employee) {
    return <></>
  }

  return (
    <Space direction="horizontal">
      <Tooltip
        title={`${employee.firstName}`}
        open={type == 'full' ? false : undefined}
      >
        <Avatar
          size={size == 'large' ? 60 : size}
          style={{
            color: '#fff',
            backgroundColor: '#5d5df6',
            fontSize: size == 'small' ? 11 : undefined,
          }}
        >
          <div style={{ textTransform: 'uppercase' }}>
            {employee.firstName && employee.firstName.slice(0, 1)}
            {employee.lastName && employee.lastName.slice(0, 1)}
            {!employee.firstName && !employee.lastName && '--'}
          </div>
        </Avatar>
      </Tooltip>
      {type == 'full' && (
        <Space.Compact direction="vertical">
          <Space.Compact direction="vertical">
            <Text>
              {employee.firstName} {employee.lastName}
            </Text>
            <Space>
              {size != 'small' && (
                <Text type="secondary">#{employee.matricule}</Text>
              )}
              <Link to={'mailto:' + employee.email}>
                <Text type="secondary">
                  <MailOutlined />
                </Text>
              </Link>
            </Space>
          </Space.Compact>
        </Space.Compact>
      )}
    </Space>
  )
}

export type EmployeeAvatarType = { Avatar: typeof EmployeeAvatar }
