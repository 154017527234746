import {
  SBSelectRaw,
  SBState,
  SBStateUUID,
  baseReducers,
  getIdOrModelId,
  getUUIDOrModelUUID,
} from '../utils/ReducerHelper'
import {
  SBAPICreate,
  SBAPIDelete,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIUpdate,
} from '../utils/SBAPIHelper'
import { CampaignEmployee } from '../models/Campaign'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { CAMPAIGN_EMPLOYEE_URL } from '../utils/urls'
import { campaignEmployeeSchema } from '../models/schema'

const initialState: SBStateUUID<CampaignEmployee> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'campaignEmployee',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getCampaignEmployeesSuccess,
  setQuery: setCampaignEmployeeQuery,
  reset: resetCampaignEmployeeState,
  resetQueryAndIds: resetCampaignEmployeeQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: CampaignEmployee }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<string[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<string | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectCampaignEmployees = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedCampaignEmployee = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectCampaignEmployeeById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectCampaignEmployeesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedCampaignEmployee =
  (campaignEmployee: CampaignEmployee | string) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        getUUIDOrModelUUID<CampaignEmployee>(campaignEmployee)
      )
    )

export const getCampaignEmployees = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<CampaignEmployee>(
    CAMPAIGN_EMPLOYEE_URL,
    params,
    [campaignEmployeeSchema],
    slice.actions
  )

export const createCampaignEmployee = (campaignEmployee: CampaignEmployee) =>
  SBAPICreate<CampaignEmployee>(
    campaignEmployee,
    CAMPAIGN_EMPLOYEE_URL,
    campaignEmployeeSchema,
    slice.actions
  )

export const getCampaignEmployeeWithId = (id: number) =>
  SBAPIFetchDispatch<CampaignEmployee>(
    `${CAMPAIGN_EMPLOYEE_URL}/${id}`,
    campaignEmployeeSchema,
    slice.actions
  )

export const updateCampaignEmployee = (campaignEmployee: CampaignEmployee) =>
  SBAPIUpdate<CampaignEmployee>(
    campaignEmployee,
    `${CAMPAIGN_EMPLOYEE_URL}/${campaignEmployee.id}`,
    slice.actions
  )

export const deleteCampaignEmployee = (campaignEmployee: CampaignEmployee) =>
  SBAPIDelete<CampaignEmployee>(
    campaignEmployee,
    `${CAMPAIGN_EMPLOYEE_URL}/${campaignEmployee.id}`,
    slice.actions
  )
