import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  OrderedListOutlined,
  StarOutlined,
  AlignLeftOutlined,
  FontSizeOutlined,
  CheckSquareOutlined,
} from '@ant-design/icons'
import { FormItemType } from '../models/FormItemType'
import { Form } from '../models/Form'
import axios from 'axios'
import { FORM_URL } from '../utils/urls'

type FormContextProps = {
  form: Form
  updateForm: (form: Form) => void
  updateActiveTabId: (id: string) => void
  itemTypes: FormItemType[]
  activeTabId: string
  onSaveClicked: (values: any) => void
}

const initFormContextPropsState: FormContextProps = {
  form: {
    id: 0,
    name: 'Nouveau formulaire',
    pages: [{ id: 0, name: 'Nouvelle page', items: [], order: 1 }],
  },
  updateForm: () => {},
  itemTypes: [],
  activeTabId: '0',
  updateActiveTabId: () => {},
  onSaveClicked: (values) => {},
}

const FormContext = createContext<FormContextProps>(initFormContextPropsState)

const useForms = () => useContext(FormContext)

interface Props {
  initialValue?: Form
  onSubmit: (values: any) => void
}

const FormContextProvider = ({
  initialValue,
  children,
  onSubmit,
}: PropsWithChildren<Props>) => {
  const [form, setForm] = useState<Form>(initFormContextPropsState['form'])
  const [itemTypes, setItemTypes] = useState<FormItemType[]>(
    initFormContextPropsState['itemTypes']
  )
  const [activeTabId, setActiveTabId] = useState<string>(
    initFormContextPropsState['activeTabId']
  )

  const updateForm = (form: Form): void => {
    setForm(form)
  }

  const updateActiveTabId = (id: string): void => {
    setActiveTabId(id)
  }

  useEffect(() => {
    axios.get(`${FORM_URL}/item-types`).then((response) => {
      setItemTypes(response.data.data)
    })
  }, [])

  const onSaveClicked = () => {
    onSubmit(form)
  }

  useEffect(() => {
    if (!initialValue) return
    updateForm(initialValue)
    setActiveTabId(initialValue.pages[0].id.toString())
    // alert('pute')
  }, [])

  return (
    <FormContext.Provider
      value={{
        form,
        updateForm,
        itemTypes,
        activeTabId,
        updateActiveTabId,
        onSaveClicked,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export { FormContextProvider, useForms }
