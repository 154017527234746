import { Typography } from 'antd'
import { useForms } from '../../../contexts/FormContext'
import { WarningOutlined } from '@ant-design/icons'

const { Title } = Typography

export const PageTitle = () => {
  const { form, updateForm } = useForms()

  return (
    <>
      {form && (
        <Title
          level={3}
          editable={{
            onChange: (value) => {
              updateForm({ ...form, name: value })
            },
          }}
        >
          {form.name}
        </Title>
      )}
    </>
  )
}

export type PageTitleType = { PageTitle: typeof PageTitle }
