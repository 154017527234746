import { Navigate, Route, Routes } from 'react-router-dom'
import CompaniesPage from '../../pages/admin/settings/CompaniesPage'
import JobsPage from '../../pages/admin/settings/JobsPage'
import { LayoutSettings } from '../../layout/LayoutSettings'
import AdminsPage from '../../pages/admin/settings/AdminsPage'

const SettingsRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutSettings />}>
        <Route index path="companies" element={<CompaniesPage />} />
        <Route path="jobs" element={<JobsPage />} />
        <Route path="admins" element={<AdminsPage />} />

        <Route path="*" element={<Navigate to={'companies'} />} />
      </Route>
    </Routes>
  )
}

export default SettingsRoutes
