import { useRef } from 'react'
import { SBRMFormInterface, SBRMType } from './SBRMModel'
import { SBRMState } from '../../store/SBRMReducer'
import { useAppSelector } from '../../utils/hooks'
import { Employee } from '../../components/employee/Employee'
import { Company } from '../../components/company/Company'
import { Job } from '../../components/job/Job'
import { Form } from '../../components/form/Form'
import { Campaign } from '../../components/campaign/Campaign'

// Form imports

type useSBRMformsProps = {
  isNested: boolean
}

const useSBRMforms = ({
  isNested,
}: useSBRMformsProps): {
  refs: Record<SBRMType, React.RefObject<SBRMFormInterface>>
  forms: Record<SBRMType, JSX.Element>
} => {
  const SBRMrefs = {
    [SBRMType.job]: useRef<SBRMFormInterface>(null),
    [SBRMType.company]: useRef<SBRMFormInterface>(null),
    [SBRMType.employee]: useRef<SBRMFormInterface>(null),
    [SBRMType.form]: useRef<SBRMFormInterface>(null),
    [SBRMType.campaign]: useRef<SBRMFormInterface>(null),
    [SBRMType.campaignEmployee]: useRef<SBRMFormInterface>(null),
    [SBRMType.admin]: useRef<SBRMFormInterface>(null),
  }

  const SBRMforms = {
    [SBRMType.job]: (
      <Job.Form ref={SBRMrefs[SBRMType.job]} isNested={isNested} />
    ),
    [SBRMType.company]: (
      <Company.Form ref={SBRMrefs[SBRMType.company]} isNested={isNested} />
    ),
    [SBRMType.employee]: (
      <Employee.Form ref={SBRMrefs[SBRMType.employee]} isNested={isNested} />
    ),
    [SBRMType.form]: (
      <Form.Form ref={SBRMrefs[SBRMType.form]} isNested={isNested} />
    ),
    [SBRMType.campaign]: (
      <Campaign.Form ref={SBRMrefs[SBRMType.campaign]} isNested={isNested} />
    ),
    [SBRMType.campaignEmployee]: <></>,
    [SBRMType.admin]: <></>,
  }
  return { refs: SBRMrefs, forms: SBRMforms }
}

const useSBRMData = (isNested: boolean): SBRMState => {
  const {
    isOpen: SBRMisOpen,
    entity: SBRMentity,
    action: SBRMaction,
    metadata: SBRMmetadata,
  } = useAppSelector((state) => state.SBRM)
  const {
    isOpen: SBRMnestedIsOpen,
    entity: SBRMnestedEntity,
    action: SBRMnestedAction,
    metadata: SBRMnestedMetadata,
  } = useAppSelector((state) => state.SBRM.nested)

  return {
    isOpen: isNested ? SBRMnestedIsOpen : SBRMisOpen,
    entity: isNested ? SBRMnestedEntity : SBRMentity,
    action: isNested ? SBRMnestedAction : SBRMaction,
    metadata: SBRMmetadata,
  }
}

export { useSBRMforms, useSBRMData }
