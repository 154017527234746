import qs from 'qs'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { schema, normalize, NormalizedSchema } from 'normalizr'
import { TableParams } from '../models/TableParams'
import { AppDispatch } from '../store/store'
import { getJobsSuccess } from '../store/JobReducer'
import { APIResponse, MessageResponse } from './setupAxios'
import { getCompaniesSuccess } from '../store/CompanyReducer'
import { getEmployeesSuccess } from '../store/EmployeeReducer'
import { getFormsSuccess } from '../store/FormReducer'
import { getCampaignsSuccess } from '../store/CampaignReducer'
import { getCampaignEmployeesSuccess } from '../store/CampaignEmployeeReducer'
import { getAdminsSuccess } from '../store/AdminReducer'

/**
 * Fetch resources paginated
 *
 * Passing `setIds` or `setPagination` as `false` allows to bypass these actions
 */
export const SBAPIFetchPaginatedDispatch =
  <T>(
    endpoint: string,
    params: TableParams,
    schema: schema.Entity | schema.Entity[],
    actions: any,
    startLoading?: any,
    setIds?: any | null | false,
    setPagination?: any | null | false
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(startLoading ? startLoading() : actions.startLoading())
    return SBAPIFetchPaginated(endpoint, params)
      .then((data: any) => {
        const normalizedData = normalize(data.data, schema)
        // Fill store with Normalized data
        dispatch(fillStoreWithNormalizedData(normalizedData))

        // Set store IDs
        if (setIds !== false) {
          dispatch(
            setIds
              ? setIds(normalizedData.result)
              : actions.setIds(normalizedData.result)
          )
        }

        // Set store pagination
        if (setPagination !== false) {
          dispatch(
            setPagination
              ? setPagination({
                  total: data.totalRecords,
                  current: data.currentPage,
                })
              : actions.setPagination({
                  total: data.totalRecords,
                  current: data.currentPage,
                })
          )
        }

        actions.stopLoading ? dispatch(actions.stopLoading()) : {}

        // Return data for other .then()
        return data
      })
      .catch((reason: { message: string | undefined; error: AxiosError }) => {
        actions.hasError ? dispatch(actions.hasError(reason.message)) : {}
        throw reason // Throw error again to allow execution of others catch()
      })
  }
export const SBAPIFetchPaginated = async <T>(
  endpoint: string,
  params: TableParams
) =>
  await axios
    .get(`${endpoint}?${qs.stringify(params)}`)
    .then((response: AxiosResponse<APIResponse<T>>) => {
      const data = response.data
      return data
    })
    .catch((error: AxiosError) => {
      const response = error.response
      const data =
        response !== undefined && response.hasOwnProperty('data')
          ? (error.response?.data as APIResponse<MessageResponse>)
          : undefined
      const errorMessage = data !== undefined ? (data.data?.message ?? '') : ''
      throw { message: errorMessage, error: error } // Throw error again to allow execution of others catch()
    })

export const SBAPIFetchDispatch =
  <T>(
    endpoint: string,
    schema: schema.Entity | schema.Entity[],
    actions: any
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())
    return SBAPIFetch(endpoint)
      .then((data) => {
        const normalizedData = normalize(data.data, schema)
        dispatch(fillStoreWithNormalizedData(normalizedData))
        actions.stopLoading ? dispatch(actions.stopLoading()) : {}
      })
      .catch((reason: { message: string | undefined; error: AxiosError }) => {
        actions.hasError ? dispatch(actions.hasError(reason.message)) : {}
        throw reason // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIFetch = async <T>(endpoint: string, params?: any) =>
  await axios
    .get(params ? `${endpoint}?${qs.stringify(params)}` : endpoint)
    .then((response) => {
      const data = response.data
      return data
    })
    .catch((error: AxiosError) => {
      const data = error.response?.data as APIResponse<MessageResponse>
      const errorMessage = data.data?.message ?? ''
      throw { message: errorMessage, error: error } // Throw error again to allow execution of others catch()
    })

export const SBAPICreate =
  <T>(
    model: T,
    endpoint: string,
    schema: schema.Entity | schema.Entity[],
    actions: any
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())
    return await axios
      .post(endpoint, model)
      .then((response) => {
        // TODO: Do we really need to process creation result ?
        // As index requests might be triggered after creation...
        const data = response.data
        if (!data.data) {
          // We still need to stop loading
          actions.stopLoading ? dispatch(actions.stopLoading()) : {}
          return
        }
        const normalizedData = normalize(data.data, schema)
        dispatch(fillStoreWithNormalizedData(normalizedData))
        actions.stopLoading ? dispatch(actions.stopLoading()) : {}
        return data
      })
      .catch((error: AxiosError) => {
        const data = error.response?.data as APIResponse<MessageResponse>
        actions.hasError
          ? dispatch(actions.hasError(data.data?.message ?? ''))
          : {}

        throw error // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIUpdate =
  <T>(model: T, endpoint: string, actions: any) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())

    /**
     * The plan is to immediately dispatch the updated item
     * However in case of error we need to keep a copy to revert the change
     */
    const oldModel = Object.assign({}, model)
    dispatch(actions.updateItemSuccess(model))

    return await axios
      .put(endpoint, model)
      .then((response) => {
        // We do not process the result here, kick back and relax ...
      })
      .catch((error: AxiosError) => {
        const data = error.response?.data as APIResponse<MessageResponse>
        // Revert the model update
        dispatch(actions.updateItemSuccess(oldModel))
        actions.hasError
          ? dispatch(actions.hasError(data.data?.message ?? ''))
          : {}
        throw error // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIDelete =
  <T>(model: T, endpoint: string, actions: any) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())
    return await axios
      .delete(endpoint)
      .then(() => {
        dispatch(actions.deleteItemSuccess(model))
      })
      .catch((error: AxiosError) => {
        const data = error.response?.data as APIResponse<MessageResponse>
        actions.hasError
          ? dispatch(actions.hasError(data.data?.message ?? ''))
          : {}
        throw error // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIPost = async (endpoint: string, body: any = {}) =>
  await axios
    .post(endpoint, body)
    .then((response) => {
      const data = response.data
      return data
    })
    .catch((error: AxiosError) => {
      const data = error.response?.data as APIResponse<MessageResponse>
      throw error // Throw error again to allow execution of others catch()
    })

const fillStoreWithNormalizedData =
  (
    normalizedData: NormalizedSchema<
      {
        [key: string]:
          | {
              [key: string]: any
            }
          | undefined
      },
      any
    >
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(getJobsSuccess(normalizedData.entities.job ?? {}))
    dispatch(getCompaniesSuccess(normalizedData.entities.company ?? {}))
    dispatch(getEmployeesSuccess(normalizedData.entities.employee ?? {}))
    dispatch(getFormsSuccess(normalizedData.entities.form ?? {}))
    dispatch(getCampaignsSuccess(normalizedData.entities.campaign ?? {}))
    dispatch(getAdminsSuccess(normalizedData.entities.admin ?? {}))
    dispatch(
      getCampaignEmployeesSuccess(
        normalizedData.entities.campaignEmployee ?? {}
      )
    )
  }
