import { FC } from 'react'
import { EditorWrapper, EditorWrapperType } from './editor/Wrapper'
import { PageTitle, PageTitleType } from './editor/PageTitle'
import { Tabs, TabsType } from './editor/Tabs'
import { ItemsWrapper, ItemsWrapperType } from './editor/ItemsWrapper'
import { ItemsLibrary, ItemsLibraryType } from './editor/ItemsLibrary'
import { FormItem, FormItemType } from './editor/FormItem'
import { Preview, PreviewType } from './editor/Preview'
import { FormTag, FormTagType } from './Tag'

import {
  FormItemOptionEditor,
  FormItemOptionEditorType,
} from './editor/FormItemOptionEditor'
import { Form as FormForm, FormType as FormFormType } from './form/Form'

export const Form: FC &
  EditorWrapperType &
  PageTitleType &
  ItemsWrapperType &
  ItemsLibraryType &
  FormItemType &
  PreviewType &
  FormItemOptionEditorType &
  TabsType &
  FormTagType &
  FormFormType = (): JSX.Element => <></>

Form.Wrapper = EditorWrapper
Form.PageTitle = PageTitle
Form.Tabs = Tabs
Form.ItemsWrapper = ItemsWrapper
Form.ItemsLibrary = ItemsLibrary
Form.FormItem = FormItem
Form.Preview = Preview
Form.ItemOptionEditor = FormItemOptionEditor
Form.Tag = FormTag

Form.Form = FormForm
