import {
  SBSelectRaw,
  SBState,
  baseReducers,
  getIdOrModelId,
} from '../utils/ReducerHelper'
import {
  SBAPICreate,
  SBAPIDelete,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIUpdate,
} from '../utils/SBAPIHelper'
import { Employee } from '../models/Employee'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { EMPLOYEE_URL } from '../utils/urls'
import { employeeSchema } from '../models/schema'

const initialState: SBState<Employee> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 50,
    },
  },
}

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getEmployeesSuccess,
  setQuery: setEmployeeQuery,
  reset: resetEmployeeState,
  resetQueryAndIds: resetEmployeeQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: number]: Employee }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectEmployees = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedEmployee = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectEmployeeById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectEmployeesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedEmployee =
  (employee: Employee | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Employee>(employee)))

export const getEmployees = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Employee>(
    EMPLOYEE_URL,
    params,
    [employeeSchema],
    slice.actions
  )

export const createEmployee = (employee: Employee) =>
  SBAPICreate<Employee>(employee, EMPLOYEE_URL, employeeSchema, slice.actions)

export const getEmployeeWithId = (id: number) =>
  SBAPIFetchDispatch<Employee>(
    `${EMPLOYEE_URL}/${id}`,
    employeeSchema,
    slice.actions
  )

export const updateEmployee = (employee: Employee) =>
  SBAPIUpdate<Employee>(
    employee,
    `${EMPLOYEE_URL}/${employee.id}`,
    slice.actions
  )

export const deleteEmployee = (employee: Employee) =>
  SBAPIDelete<Employee>(
    employee,
    `${EMPLOYEE_URL}/${employee.id}`,
    slice.actions
  )
