import { SBRMType } from '../../../../modules/sbrm/SBRMModel'
import {
  COMPANY_URL,
  EMPLOYEE_URL,
  FORM_URL,
  JOB_URL,
} from '../../../../utils/urls'
import { SBAsyncSelectConfig } from '../types'
import { SBModel } from '../../../../models/SBmodel'
import { Company } from '../../../company/Company'
import { Company as CompanyModel } from '../../../../models/Company'
import { Job } from '../../../job/Job'
import { Job as JobModel } from '../../../../models/Job'
import { Employee } from '../../../employee/Employee'
import { Employee as EmployeeModel } from '../../../../models/Employee'
import { Form } from '../../../form/Form'
import { Form as FormModel } from '../../../../models/Form'

const SBAsyncSelectDefaultConfig: SBAsyncSelectConfig<SBModel> = {
  [SBRMType.job]: {
    endpoint: JOB_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Job.Option model={item as unknown as JobModel} />
    ),
    footer: (shouldTriggerIndex: () => void) => <></>,
    extra: (item: SBModel) => <></>,
  },
  [SBRMType.company]: {
    endpoint: COMPANY_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Company.Option model={item as unknown as CompanyModel} />
    ),
    footer: (shouldTriggerIndex: () => void) => <></>,
    extra: (item: SBModel) => <></>,
  },
  [SBRMType.employee]: {
    endpoint: EMPLOYEE_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Employee.Option
        size={'small'}
        model={item as unknown as EmployeeModel}
      />
    ),
    footer: (shouldTriggerIndex: () => void) => <></>,
    extra: (item: SBModel) => <></>,
  },
  [SBRMType.form]: {
    endpoint: FORM_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as FormModel).name}</>,
    footer: (shouldTriggerIndex: () => void) => <></>,
    extra: (item: SBModel) => <></>,
  },
}

export default SBAsyncSelectDefaultConfig
