import { SBRMType } from './SBRMModel'
import { SBRMAction } from './SBRMModel'

enum SBRMTitleAction {
  AddNew = 'AddNew',
  CreateNew = 'CreateNew',
  Edit = 'Edit',
  Delete = 'Delete',
  Comment = 'Comment',
  View = 'View',
  Upload = 'Upload',
  Duplicate = 'Duplicate',
  Assign = 'Assign',
  Invite = 'Invite',
  Cancel = 'Cancel',
}

const SBRMTitle = (
  entity: SBRMType | undefined,
  action: SBRMAction | undefined
): string => {
  if (entity == undefined || action == undefined) return 'Error'

  const titlesBase: Record<SBRMTitleAction, string> = {
    [SBRMTitleAction.AddNew]: 'Add new ',
    [SBRMTitleAction.CreateNew]: 'Create new ',
    [SBRMTitleAction.Edit]: 'Edit ',
    [SBRMTitleAction.Delete]: 'Delete ',
    [SBRMTitleAction.Comment]: 'Comment ',
    [SBRMTitleAction.View]: 'View ',
    [SBRMTitleAction.Upload]: 'Upload ',
    [SBRMTitleAction.Duplicate]: 'Duplicate ',
    [SBRMTitleAction.Assign]: 'Assign ',
    [SBRMTitleAction.Invite]: 'Invite ',
    [SBRMTitleAction.Cancel]: 'Cancel ',
  }

  const composeTitle = (action: SBRMTitleAction, type: SBRMType): string =>
    titlesBase[action] + type

  const titles: Record<SBRMType, Partial<Record<SBRMAction, string>>> = {
    [SBRMType.job]: {
      ['create']: composeTitle(SBRMTitleAction.CreateNew, SBRMType.job),
      ['update']: composeTitle(SBRMTitleAction.Edit, SBRMType.job),
      ['delete']: composeTitle(SBRMTitleAction.Delete, SBRMType.job),
    },
    [SBRMType.company]: {
      ['create']: composeTitle(SBRMTitleAction.CreateNew, SBRMType.company),
      ['update']: composeTitle(SBRMTitleAction.Edit, SBRMType.company),
      ['delete']: composeTitle(SBRMTitleAction.Delete, SBRMType.company),
    },
    [SBRMType.admin]: {
      ['create']: composeTitle(SBRMTitleAction.CreateNew, SBRMType.admin),
      ['update']: composeTitle(SBRMTitleAction.Edit, SBRMType.admin),
      ['delete']: composeTitle(SBRMTitleAction.Delete, SBRMType.admin),
    },
    [SBRMType.employee]: {
      ['create']: composeTitle(SBRMTitleAction.CreateNew, SBRMType.employee),
      ['update']: composeTitle(SBRMTitleAction.Edit, SBRMType.employee),
      ['delete']: composeTitle(SBRMTitleAction.Delete, SBRMType.employee),
    },
    [SBRMType.form]: {
      ['create']: composeTitle(SBRMTitleAction.CreateNew, SBRMType.form),
      ['update']: composeTitle(SBRMTitleAction.Edit, SBRMType.form),
      ['delete']: composeTitle(SBRMTitleAction.Delete, SBRMType.form),
    },
    [SBRMType.campaign]: {
      ['create']: composeTitle(SBRMTitleAction.CreateNew, SBRMType.campaign),
      ['update']: composeTitle(SBRMTitleAction.Edit, SBRMType.campaign),
      ['delete']: composeTitle(SBRMTitleAction.Delete, SBRMType.campaign),
    },
    [SBRMType.campaignEmployee]: {
      ['create']: composeTitle(
        SBRMTitleAction.CreateNew,
        SBRMType.campaignEmployee
      ),
      ['update']: composeTitle(SBRMTitleAction.Edit, SBRMType.campaignEmployee),
      ['delete']: composeTitle(
        SBRMTitleAction.Delete,
        SBRMType.campaignEmployee
      ),
    },
  }

  const title = titles[entity][action]
  return title !== undefined ? title : ''
}

export default SBRMTitle
