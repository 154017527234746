import Sider from 'antd/es/layout/Sider'
import { Outlet } from 'react-router-dom'
import { Col, Layout, Row } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import SettingsSider from './components/SettingsSider'
Layout

export const LayoutSettings: React.FC = () => {
  return (
    <Layout style={{ backgroundColor: 'transparent' }}>
      <Content style={{ marginTop: 64 }}>
        <Row className="container-row">
          <Col xs={24} className="px-4">
            <Layout style={{ backgroundColor: 'transparent' }}>
              <Sider
                width={230}
                style={{ background: 'none', overflow: 'scroll' }}
              >
                <SettingsSider />
              </Sider>

              <Content
                style={{
                  padding: '0 24px',
                  minHeight: 280,
                }}
              >
                <Outlet />
              </Content>
            </Layout>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}
