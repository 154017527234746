import { Alert, App, Button, Checkbox, Flex, Space, Typography } from 'antd'
import PageTitle from '../../../components/utils/PageTitle'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Admin as AdminModel } from '../../../models/Admin'
import { useEffect } from 'react'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import SBTable from '../../../components/utils/SBTable'
import {
  getAdmins,
  selectAdmins,
  setAdminQuery,
} from '../../../store/AdminReducer'
import { selectLoggedUser } from '../../../store/UserReducer'
import { CopyOutlined } from '@ant-design/icons'

const { Text } = Typography

const AdminsPage = () => {
  const { message } = App.useApp()
  const dispatch = useAppDispatch()

  const items = useAppSelector(selectAdmins())
  const user = useAppSelector(selectLoggedUser)

  const { query, isLoading } = useAppSelector((state) => state.admin)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const columns: ColumnsType<AdminModel> = [
    {
      key: 'id',
      title: 'ID',
      render: (record: AdminModel) => record.id,
    },
    {
      key: 'azure-id',
      title: 'Azure ID',
      render: (record: AdminModel) => record.azureId,
    },
    {
      key: 'suser-admin',
      title: 'Est super admin',
      render: (record: AdminModel) => (
        <Checkbox checked={record.isSuperAdmin} disabled />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AdminModel> | SorterResult<AdminModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setAdminQuery(newQuery))
    dispatch(getAdmins(newQuery))
  }

  useEffect(() => {
    dispatch(getAdmins(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Flex vertical>
      <PageTitle title="Admins" level={5} />
      <Alert
        style={{ marginBottom: 12 }}
        message={
          <Text>
            Mon Admin Azure ID est :
            <Button
              type="text"
              icon={<CopyOutlined />}
              iconPosition="end"
              onClick={() => {
                navigator.clipboard.writeText(user?.id ?? '')
                message.success('ID copié dans le presse-papier')
              }}
            >
              <Text strong>{user?.id}</Text>
            </Button>
          </Text>
        }
      />
      <SBTable
        entity={SBRMType.admin}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={items}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Flex>
  )
}

export default AdminsPage
