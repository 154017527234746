import { Card, Input, Space, Switch, Typography, Button } from 'antd'
import { useEffect, useState } from 'react'
import { Form } from '../Form'
import { DeleteOutlined } from '@ant-design/icons'
import { FormPage } from '../../../models/FormPage'
import { FormItem as FormItemModel } from '../../../models/FormItem'
import { useForms } from '../../../contexts/FormContext'
import { getIconByTypeId } from './utils/IconHelper'

const { Text } = Typography

interface Props {
  item: FormItemModel
  page: FormPage
}

export const FormItem = ({ item, page }: Props) => {
  const { form, updateForm } = useForms()
  const [isRequired, setIsRequired] = useState<boolean>(item.required ?? true)
  const [question, setQuestion] = useState<string>(item.question)
  const [description, setDescription] = useState<string | undefined>(
    item.description
  )

  const handleUpdateItem = () => {
    const updatedForm = {
      ...form,
      pages: form.pages.map((p) =>
        p.id === page.id
          ? {
              ...p,
              items: p.items.map((i) =>
                i.id === item.id
                  ? {
                      ...i,
                      question: question,
                      description: description,
                      required: isRequired,
                    }
                  : i
              ),
            }
          : p
      ),
    }
    updateForm(updatedForm)
  }

  const handleDeleteItem = () => {
    const updatedForm = {
      ...form,
      pages: form.pages.map((p) =>
        p.id === page.id
          ? {
              ...p,
              items: p.items.filter((i) => i.id !== item.id),
            }
          : p
      ),
    }
    updateForm(updatedForm)
  }

  useEffect(() => {
    handleUpdateItem()
  }, [isRequired, question, description])

  return (
    <Card>
      <Space direction="vertical" className="w-full">
        <Space className="w-full space-between">
          <Space>
            {getIconByTypeId(item.type.id)} {item.type.name}
          </Space>
          <Space>
            {item.type.requirable && (
              <Space>
                <Text type="secondary">Requis</Text>
                <Switch
                  size="small"
                  defaultChecked={isRequired}
                  onChange={() => setIsRequired(!isRequired)}
                />
              </Space>
            )}
            <Button
              icon={<DeleteOutlined />}
              type="text"
              size="small"
              danger
              onClick={handleDeleteItem}
            />
          </Space>
        </Space>
        {item.type.id != 7 && (
          <>
            <Input
              value={question}
              size="large"
              placeholder="Titre"
              onChange={(e) => setQuestion(e.target.value)}
            />
            <Input
              value={description}
              size="middle"
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
            {item.type.optionable && <Form.ItemOptionEditor formItem={item} />}
          </>
        )}
      </Space>
    </Card>
  )
}

export type FormItemType = { FormItem: typeof FormItem }
