import { Tag, Typography } from 'antd'
import { Job as JobModel } from '../../models/Job'
import { useAppSelector } from '../../utils/hooks'
import { selectJobById } from '../../store/JobReducer'

const { Text } = Typography

interface Props {
  id?: number
  model?: JobModel
  type?: 'full' | 'condensed'
}

export const JobTag = ({ id, model }: Props) => {
  const item = model ? model : useAppSelector(selectJobById(id!))

  if (!item) {
    return <Tag>Poste supprimé</Tag>
  }

  return <Tag color="pink">{item.name}</Tag>
}

export type JobTagType = { Tag: typeof JobTag }
