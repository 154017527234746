const _BASE_API_URL = process.env.REACT_APP_API_URL
const API_URL = _BASE_API_URL + ''

export const JOB_URL = API_URL + '/jobs'
export const COMPANY_URL = API_URL + '/companies'
export const ADMIN_URL = API_URL + '/admins'
export const EMPLOYEE_URL = API_URL + '/employees'
export const FORM_URL = API_URL + '/forms'
export const CAMPAIGN_URL = API_URL + '/campaigns'
export const CAMPAIGN_EMPLOYEE_URL = API_URL + '/campaign-employees'
export const HOME_STATS_URL = API_URL + '/home/stats'
