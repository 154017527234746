import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, Menu, Space } from 'antd'
import { extractSectionFromPath } from '../../utils/RouterHelpers'
import { AimOutlined } from '@ant-design/icons'

const SettingsSider = () => {
  const { pathname } = useLocation()
  const [selectedKey, setSelectedKey] = useState<string>('companies')

  useEffect(() => {
    const menuItemSelected = extractSectionFromPath(pathname, 2)
    setSelectedKey(menuItemSelected)
  }, [pathname])

  return (
    <Card
      styles={{
        body: { padding: 10 },
      }}
      bordered={false}
    >
      <Space direction="vertical" className="w-full">
        <Menu
          mode="vertical"
          selectedKeys={[selectedKey]}
          style={{ border: 0 }}
          items={[
            {
              key: 'companies',
              label: <Link to={'companies'}>Sociétés</Link>,
              icon: <AimOutlined />,
            },
            {
              key: 'jobs',
              label: <Link to={'jobs'}>Postes</Link>,
              icon: <AimOutlined />,
            },
            {
              key: 'admins',
              label: <Link to={'admins'}>Admins</Link>,
              icon: <AimOutlined />,
            },
          ]}
        />
      </Space>
    </Card>
  )
}

export default SettingsSider
