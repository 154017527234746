import { Typography, Tabs as ANTDTabs, Card, Space, Input } from 'antd'
import { useForms } from '../../../contexts/FormContext'
import { useState } from 'react'
import { Form } from '../Form'
import { WarningOutlined } from '@ant-design/icons'
const { Title, Text } = Typography
type TargetKey = React.MouseEvent | React.KeyboardEvent | string

export const Tabs = () => {
  const { form, updateForm, activeTabId, updateActiveTabId } = useForms()

  const onChange = (newActiveKey: string) => {
    updateActiveTabId(newActiveKey)
  }

  const add = () => {
    // Vérifie que `form` est défini avant de continuer
    if (!form) return

    // Détermine la clé active pour la nouvelle page, en incrémentant l'id de la dernière page
    const lastPageId = form.pages?.[form.pages.length - 1]?.id ?? 0
    const newActiveKey = String(lastPageId + 1)

    // Crée une copie du tableau `pages` ou initialise un tableau vide si `pages` est undefined
    const newPanes = [...(form.pages ?? [])]

    // Ajoute une nouvelle page avec les informations par défaut
    newPanes.push({
      id: Number(newActiveKey),
      name: 'Nouvelle page',
      items: [],
      order: newPanes.length + 1, // Utilise la longueur de `newPanes` pour l'ordre de la nouvelle page
    })

    // Met à jour `form` avec la nouvelle page ajoutée
    updateForm({ ...form, pages: newPanes })

    // Définit la nouvelle clé active
    updateActiveTabId(newActiveKey)
  }

  const remove = (targetKey: TargetKey) => {
    let newActiveKey = activeTabId
    let lastIndex = -1

    // Trouver l'index de l'élément à supprimer
    form?.pages?.forEach((item, i) => {
      if (String(item.id) === targetKey) {
        lastIndex = i - 1
      }
    })

    // Filtrer les pages pour supprimer l'élément cible
    const newPanes =
      form?.pages?.filter((item) => String(item.id) !== targetKey) ?? []

    // Réinitialiser les champs `order` pour les pages restantes
    const reorderedPanes = newPanes.map((page, index) => ({
      ...page,
      order: index + 1, // ou `index` si l'ordre commence à 0
    }))

    // Mettre à jour la clé active si nécessaire
    if (reorderedPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = String(reorderedPanes[lastIndex].id)
      } else {
        newActiveKey = String(reorderedPanes[0].id)
      }
    }

    // Mettre à jour `form` avec les pages modifiées et réinitialisées
    if (form != undefined) {
      updateForm({ ...form, pages: reorderedPanes })
    }

    updateActiveTabId(newActiveKey)
  }

  const onEdit = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove'
  ) => {
    if (action === 'add') {
      add()
    } else {
      remove(targetKey)
    }
  }

  const handleUpdatePageLabel = (name: string, id: number) => {
    // Vérifie que `form.pages` est défini
    if (!form?.pages) return

    // Crée une copie du tableau `pages` pour éviter les mutations directes
    const updatedPages = [...form.pages]

    // Trouve l'index de la page correspondant à l'id spécifié
    const pageIndex = updatedPages.findIndex((page) => page.id === id)

    // Si l'élément n'est pas trouvé, sortir de la fonction
    if (pageIndex === -1) return

    // Met à jour le label de la page trouvée
    updatedPages[pageIndex] = {
      ...updatedPages[pageIndex],
      name,
    }

    // Met à jour l'état `form` avec le tableau `pages` modifié
    updateForm({ ...form, pages: updatedPages })
  }
  return (
    <>
      {form && (
        <ANTDTabs
          type="editable-card"
          onChange={onChange}
          activeKey={activeTabId}
          onEdit={onEdit}
          items={form?.pages?.map((page) => ({
            key: String(page.id),
            children: (
              <Space.Compact
                className="w-full"
                direction="vertical"
                style={{
                  borderStyle: 'solid',
                  borderColor: '#f0f0f0',
                  borderBottomWidth: 1,
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderTopWidth: 0,
                }}
              >
                <Space
                  className="w-full"
                  direction="vertical"
                  style={{
                    background: '#fff',
                    padding: 20,
                    borderStyle: 'solid',
                    borderColor: '#f0f0f0',
                    borderBottomWidth: 1,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                  }}
                >
                  <Text>Nom de la page:</Text>
                  <Input
                    style={{ fontWeight: '400', fontSize: 14 }}
                    className="m-0"
                    onChange={(e) => {
                      handleUpdatePageLabel(e.target.value, page.id)
                    }}
                    value={page.name}
                  />{' '}
                </Space>
                <Form.ItemsWrapper page={page} />
              </Space.Compact>
            ),
            closable: form.pages && form.pages?.length > 1,
            label: (
              <Space>
                <Title
                  style={{ fontWeight: '400', fontSize: 14 }}
                  className="m-0"
                  level={5}
                >
                  {page.name}
                </Title>
                {page.items.length < 1 && (
                  <WarningOutlined style={{ color: 'red' }} />
                )}
              </Space>
            ),
          }))}
        />
      )}
    </>
  )
}

export type TabsType = { Tabs: typeof Tabs }
