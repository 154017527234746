import { Form, FormInstance, Input, Result } from 'antd'
import { useEffect } from 'react'
import { selectSelectedEmployee } from '../../../store/EmployeeReducer'
import { useAppSelector } from '../../../utils/hooks'
import { requiredRule, stringRule } from '../../../utils/rules'
import { WarningOutlined } from '@ant-design/icons'

interface Props {
  form: FormInstance
}

const Delete = ({ form }: Props) => {
  const item = useAppSelector(selectSelectedEmployee())

  useEffect(() => {
    form.setFieldsValue(item)
  }, [item])

  if (!item) return <></>

  return (
    <Form form={form} layout="vertical">
      <Result
        title="Êtes-vous de vouloir supprimer ?"
        icon={<WarningOutlined style={{ color: '#dc4446' }} />}
      />
      <Form.Item name="id" required rules={[requiredRule()]} className="d-none">
        <Input type="hidden" />
      </Form.Item>
    </Form>
  )
}

export default Delete
