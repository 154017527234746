import { Button, Flex, Space } from 'antd'
import PageTitle from '../../components/utils/PageTitle'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import SBTable from '../../components/utils/SBTable'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { getForms, selectForms, setFormQuery } from '../../store/FormReducer'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Form as FormModel } from '../../models/Form'
import { useNavigate } from 'react-router-dom'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { useEffect } from 'react'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'

const FormsPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const items = useAppSelector(selectForms())
  const { query, isLoading } = useAppSelector((state) => state.form)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const columns: ColumnsType<FormModel> = [
    {
      key: 'name',
      title: 'Nom',
      width: 300,
      render: (record: FormModel) => record.name,
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: FormModel) => (
        <Space>
          {record.isLocked ? (
            <Button
              onClick={() => {
                navigate(record.id.toString())
              }}
              icon={<EyeOutlined />}
            />
          ) : (
            <>
              <Button
                onClick={() => {
                  navigate(record.id.toString())
                }}
                icon={<EditOutlined />}
              />
              <Actions
                actions={['delete']}
                entity={SBRMType.form}
                entityId={record.id}
              />
            </>
          )}
        </Space>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<FormModel> | SorterResult<FormModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setFormQuery(newQuery))
    dispatch(getForms(newQuery))
  }

  useEffect(() => {
    dispatch(getForms(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Flex vertical className="container-row">
      <PageTitle
        level={5}
        title="Formulaires"
        toolbar={
          <Button
            title="Create"
            type="primary"
            onClick={() => {
              navigate('create')
            }}
          >
            Ajouter
          </Button>
        }
      />

      <SBTable
        entity={SBRMType.form}
        scroll={{ x: 600 }}
        columns={columns}
        hideCreateButton
        rowKey={(record) => record.id}
        dataSource={items}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Flex>
  )
}

export default FormsPage
