import React from 'react'
import { Button, Space } from 'antd'
import { SBRMAction, SBRMType } from '../SBRMModel'

interface Props {
  isLoading: boolean
  entity: SBRMType | undefined
  action: SBRMAction
  onSave: () => void
  onUpdate: () => void
  onDelete: () => void
  onClose: () => void
  style?: React.CSSProperties
  displayCloseButton?: boolean
}

const SBRMActionsWrapper = ({
  isLoading,
  entity,
  action,
  onSave,
  onUpdate,
  onClose,
  onDelete,
  style,
  displayCloseButton = true,
}: Props) => {
  return (
    <div style={style}>
      <Space className="p-2 text-right">
        {displayCloseButton && (
          <Button onClick={onClose} disabled={isLoading}>
            Fermer
          </Button>
        )}
        {entity && (action === 'create' || action === 'duplicate') && (
          <Button onClick={onSave} type="primary" loading={isLoading}>
            Enregistrer
          </Button>
        )}
        {entity && action === 'update' && (
          <Button
            onClick={onUpdate}
            type="primary"
            color="primary"
            loading={isLoading}
          >
            Enregistrer
          </Button>
        )}
        {entity && action === 'delete' && (
          <Button danger onClick={onDelete} type="primary" loading={isLoading}>
            Supprimer
          </Button>
        )}
      </Space>
    </div>
  )
}

export default SBRMActionsWrapper
