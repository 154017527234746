import { green } from '@ant-design/colors'
import { Alert, App, Button } from 'antd'
import { Campaign } from '../../models/Campaign'
import axios from 'axios'
import { CAMPAIGN_URL } from '../../utils/urls'
import { useState } from 'react'

interface Props {
  campaign: Campaign
}

export const SendEmail = ({ campaign }: Props) => {
  const { message } = App.useApp()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onClick = () => {
    setIsLoading(true)
    axios
      .post(CAMPAIGN_URL + `/${campaign.id}/send-email`)
      .then(() => {
        message.success('Emails envoyés')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Alert
      type="success"
      message={'Envoyer la campagne'}
      style={{ color: green[6], fontWeight: 600 }}
      action={
        <Button
          type="primary"
          loading={isLoading}
          onClick={onClick}
          style={{
            background: green[6],
            borderColor: green[6],
            color: green[0],
          }}
        >
          Envoyer
        </Button>
      }
    />
  )
}

export type CampaignSendEmailType = { SendEmail: typeof SendEmail }
